import React from "react";


const Loader = () => {
  return (
    <>
      <style>
        {`
.placeholder-wrapper {
    max-width: 24.306vw;
    display: flex;
    gap: 12px;
    margin: auto;
    margin-bottom: 20px;
}
.placeholder-left {
    background: #f6f7f8;
    width: 30%;
    border-radius: 20px;
}
.placeholder-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: transparent !important;
}
.line-1 {
    background: #f6f7f8;
    height: 22px;
    border-radius: 20px;
    width: 90%;
}
.line-2 {
    background: #f6f7f8;
    height: 22px;
    border-radius: 20px;
    width: 80%;
}
.line-3 {
    background: #f6f7f8;
    height: 22px;
    border-radius: 20px;
}
.bottom-linewrapper {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    background-color: transparent !important;
}
.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f6f7f8;
    box-shadow:none;
}
.loader-head {
    height: calc(100vh - 175px);
    overflow: hidden;
  }
.middle-line {
    background: #f6f7f8;
    height: 15px;
    border-radius: 20px;
    width: 50%;
}
.last-line {
    background: #f6f7f8;
    height: 15px;
    border-radius: 20px;
    width: 25%;
}
.placeholder-left, .line-1, .line-2, .line-3, .circle, .middle-line, .last-line {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderSkeleton;
    -webkit-animation-timing-function: linear;
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8));
    background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 195px;
}

.placeholder-wrapper {
    // -webkit-animation-name:skeletonAnimate;
    // background-repeat:repeat;
    // background-size:50% auto;
    // position: relative;
    // background: linear-gradient(to right, #e5e5e5, #d5d5d5 20%, #e5e5e5 70%);
    // background-size: 200% 100%;
    // animation: ph-gradiant 1.5s linear infinite;
    
  }
@-webkit-keyframes placeholderSkeleton {
  // 0% {
  //   background-position: -468px 0;
  // }
  // 100% {
  //   background-position: 468px 0;
  // }
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }



}

@keyframes ph-gradiant {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
.ph-animated {
  position: relative;
  background: linear-gradient(to right, #e5e5e5, #d5d5d5 20%, #e5e5e5 70%);
  background-size: 200% 100%;
  animation: ph-gradiant 1.5s linear infinite;
}
@media screen and (max-width:767px){
  .placeholder-wrapper {
    max-width: 100%;
}
}
`}
      </style>

      <div className="placeholder-wrapper">
        <div className="placeholder-left ph-animated"></div>
        <div className="placeholder-right ">
          <div className="line-1 ph-animated"></div>
          <div className="line-2 ph-animated"></div>
          <div className="line-3 ph-animated"></div>
          <div className="bottom-linewrapper">
            <div className="circle ph-animated"></div>
            <div className="middle-line ph-animated"></div>
            <div className="last-line ph-animated"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
