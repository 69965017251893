import React from "react";
import "./ModalForPrivacyNotice.css";

const ModalForPrivacyNotice = ({
  isPopUpOpenOfPrivacyNotice,
  setIsPopUpOpenOfPrivacyNotice,
}) => {
  const closeModal = () => {
    setIsPopUpOpenOfPrivacyNotice(false);
  };

  return (
    <div
      className="custom-modal-privacy-terms"
      style={{ display: isPopUpOpenOfPrivacyNotice ? "block" : "none" }}
    >
      <div className="modal-dialog-privacy-terms">
        <div className="modal-content-privacy-terms">
          <div className="modal-header-privacy-terms">
            <h5 className="modal-title">Política de Privacidad de Tripticke</h5>
            <button
              type="button"
              className="close-privacy-terms"
              onClick={closeModal}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-footer-privacy-terms">
            <p>Fecha de última actualización: 26 de octubre</p>
            <h4>1. Introducción</h4>

            <p>
              Bienvenido a Triptick. Nos comprometemos a proteger la privacidad
              de nuestros usuarios. Esta política describe cómo recopilamos,
              usamos y protegemos tu información personal.
            </p>

            <h4>2. Recopilación de Información</h4>
            <p>
              Información proporcionada por el usuario: Cuando te registras o
              usas Triptick, nos proporcionas información como tu nombre,
              dirección de correo electrónico, ubicación y otros detalles.
              Información recopilada automáticamente: Recopilamos información
              sobre cómo utilizas Triptick, como las páginas que visitas, la
              frecuencia de acceso y la duración de tu visita.
            </p>

            <h4>3. Uso de la Información</h4>
            <p>
              Utilizamos la información que recopilamos para:
              <ul>
                <li>Proporcionar y mejorar nuestros servicios.</li>
                <li>Responder a tus preguntas o solicitudes.</li>
                <li>
                  Enviar comunicaciones relacionadas con el servicio, como
                  notificaciones y actualizaciones.
                </li>
              </ul>
            </p>

            <h4>4. Compartir Información</h4>
            <p>
              No vendemos ni compartimos tu información personal con terceros
              para fines de marketing. Podemos compartir tu información con:
              <ul>
                <li>
                  Proveedores de servicios que nos ayudan a operar y mejorar
                  Triptick.
                </li>
                <li>
                  Cuando sea necesario para cumplir con leyes o regulaciones, o
                  en respuesta a una solicitud legal.
                </li>
              </ul>
            </p>

            <h4>5. Seguridad</h4>
            <p>
              Implementamos medidas de seguridad para proteger tu información
              personal de accesos no autorizados, alteraciones, divulgaciones o
              destrucciones. Sin embargo, ninguna medida de seguridad es 100%
              segura y no podemos garantizar la seguridad total de tu
              información.
            </p>

            <h4>6. Cookies y Tecnologías Similares</h4>
            <p>
              Triptick utiliza cookies y tecnologías similares para mejorar tu
              experiencia de usuario y recopilar datos sobre cómo interactúas
              con nuestra plataforma.
            </p>

            <h4>7. Derechos del Usuario</h4>
            <p>
              Puedes solicitar acceso, corrección o eliminación de tu
              información personal en cualquier momento contactándonos en
              info@metasoftwarefactory.com.
            </p>

            <h4>8. Cambios en la Política de Privacidad</h4>
            <p>
              Podemos actualizar nuestra Política de Privacidad ocasionalmente.
              Publicaremos cualquier cambio en esta página y, si son
              significativos, te proporcionaremos una notificación más
              destacada.
            </p>

            <h4>9. Contacto</h4>
            <p>
              Si tienes preguntas o inquietudes sobre nuestra Política de
              Privacidad, contáctanos en info@metasoftwarefactory.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalForPrivacyNotice;
