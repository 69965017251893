export const missingCountries = [
  { name: "Singapore", latLng: [1.3521, 103.8198] },
  { name: "BouvetIsland", latLng: [54.4208, 3.3464] },
  { name: "Bermuda", latLng: [32.3078, 64.7505] },
  { name: "Andorra", latLng: [42.5063, 1.5218] },
  { name: "AmericanSamoa", latLng: [14.271, 170.1322] },
  { name: "ÅlandIslands", latLng: [60.1785, 19.9156] },
];

export const countries = {
  AF: 0,
  AL: 0,
  DZ: 0,
  AD: 0,
  AO: 0,
  AG: 0,
  AR: 0,
  AM: 0,
  AU: 0,
  AT: 0,
  AZ: 0,
  BS: 0,
  BH: 0,
  BD: 0,
  BB: 0,
  BY: 0,
  BE: 0,
  BZ: 0,
  BJ: 0,
  BT: 0,
  BO: 0,
  BA: 0,
  BW: 0,
  BR: 0,
  BN: 0,
  BG: 0,
  BF: 0,
  BI: 0,
  KH: 0,
  CM: 0,
  CA: 0,
  CV: 0,
  CF: 0,
  TD: 0,
  CL: 0,
  CN: 0,
  CO: 0,
  KM: 0,
  CG: 0,
  CD: 0,
  CR: 0,
  CI: 0,
  HR: 0,
  CU: 0,
  CY: 0,
  CZ: 0,
  DK: 0,
  DJ: 0,
  DM: 0,
  DO: 0,
  EC: 0,
  EG: 0,
  SV: 0,
  GQ: 0,
  ER: 0,
  EE: 0,
  ET: 0,
  EH: 0,
  FJ: 0,
  FI: 0,
  FR: 0,
  GA: 0,
  GM: 0,
  GE: 0,
  DE: 0,
  GH: 0,
  GR: 0,
  GD: 0,
  GT: 0,
  GN: 0,
  GW: 0,
  GY: 0,
  GL: 0,
  HT: 0,
  HN: 0,
  HU: 0,
  IS: 0,
  IN: 0,
  ID: 0,
  IR: 0,
  IQ: 0,
  IE: 0,
  IL: 0,
  IT: 0,
  JM: 0,
  JP: 0,
  JO: 0,
  KZ: 0,
  KE: 0,
  KI: 0,
  KP: 0,
  KR: 0,
  KW: 0,
  KG: 0,
  LA: 0,
  LV: 0,
  LB: 0,
  LS: 0,
  LR: 0,
  LY: 0,
  LI: 0,
  LT: 0,
  LU: 0,
  MK: 0,
  MG: 0,
  MW: 0,
  MY: 0,
  MV: 0,
  ML: 0,
  MT: 0,
  MH: 0,
  MR: 0,
  MU: 0,
  MX: 0,
  FM: 0,
  MD: 0,
  MC: 0,
  MN: 0,
  ME: 0,
  MA: 0,
  MZ: 0,
  MM: 0,
  NA: 0,
  NC: 0,
  NR: 0,
  NP: 0,
  NL: 0,
  NZ: 0,
  NI: 0,
  NE: 0,
  NG: 0,
  NO: 0,
  OM: 0,
  PK: 0,
  PW: 0,
  PS: 0,
  PA: 0,
  PG: 0,
  PY: 0,
  PE: 0,
  PH: 0,
  PL: 0,
  PT: 0,
  QA: 0,
  RO: 0,
  RU: 0,
  RW: 0,
  KN: 0,
  LC: 0,
  VC: 0,
  WS: 0,
  SM: 0,
  ST: 0,
  SA: 0,
  SN: 0,
  RS: 0,
  SC: 0,
  SL: 0,
  SG: 0,
  SK: 0,
  SI: 0,
  SB: 0,
  SO: 0,
  ZA: 0,
  SS: 0,
  ES: 0,
  LK: 0,
  SD: 0,
  SR: 0,
  SZ: 0,
  SE: 0,
  CH: 0,
  SY: 0,
  TW: 0,
  TJ: 0,
  TZ: 0,
  TH: 0,
  TL: 0,
  TG: 0,
  TO: 0,
  TT: 0,
  TN: 0,
  TR: 0,
  TM: 0,
  TV: 0,
  UG: 0,
  UA: 0,
  AE: 0,
  GB: 0,
  US: 0,
  UY: 0,
  UZ: 0,
  VU: 0,
  VA: 0,
  VE: 0,
  VN: 0,
  YE: 0,
  ZM: 0,
  ZW: 0,
  TF: 0,
  XS: 0,
  XK: 0,
  PR: 0,
  FK: 0
};

export const countryName = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AD": "Andorra",
  "AO": "Angola",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BR": "Brazil",
  "BN": "Brunei",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "CF": "Central African Republic",
  "EH": "W. Sahara",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo (Brazzaville)",
  "CD": "Congo (Kinshasa)",
  "CR": "Costa Rica",
  "CI": "Cote d'Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GL": "Greenland",
  "GR": "Greece",
  "GD": "Grenada",
  "GT": "Guatemala",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HN": "Honduras",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "North Korea",
  "KR": "South Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Laos",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MA": "Morocco",
  "MK": "North Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "MX": "Mexico",
  "FM": "Micronesia",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MZ": "Mozambique",
  "MM": "Myanmar (Burma)",
  "NA": "Namibia",
  "NC": "New Caledonia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PL": "Poland",
  "PT": "Portugal",
  "QA": "Qatar",
  "RO": "Romania",
  "RU": "Russia",
  "RW": "Rwanda",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome and Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SZ": "Eswatini",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syria",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VA": "Vatican City",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
  "TF": "Fr. S. Antarctic Lands",
  "XS": "Somaliland",
  "XK": "Kosovo",
  "PR": "Puerto Rico",
  "FK": "Falkland Is."
};

export const colorScale = ["#BEDDFA", "#E27610","#107DE2"];


