import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./style/authentication.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASEURL } from "../../api";
const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  // console.log("token", token);
  // console.log("email", email);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [initialValues, setInitialValues] = useState({
    password: "",
    confirm_password: "",
    email: email,
    token: token,
  });

  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const emailParam = urlParams.searchParams.get("email");
    const tokenParam = urlParams.searchParams.get("token");

    setInitialValues({
      ...initialValues,
      email: emailParam || "",
      token: tokenParam || "",
    });
  }, []);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = {
        password: values.password,
        confirm_password: values.confirm_password,
        email: values.email,
        token: values.token,
      };
      const response = await axios.post(`${BASEURL}/changePassword`, formData);
      if (response.data.status === true) {
        setIsResetSuccessful(true);
        toast.success(response.data.message);
        setTimeout(() => {
          navigate(`/login`);
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Password reset failed", error);
    }
  };

  return (
    <>
    <header>
        <div className="container-fluid">
          <div className="navber">
            <div className="brand-logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="112"
                height="27"
                viewBox="0 0 112 27"
                fill="none"
                onClick={() => {
                  navigate(`/`);
                }}
              >
                <path
                  d="M87.8547 20.989C85.7195 20.989 83.9684 20.2914 82.6013 18.8962C81.2343 17.5009 80.5507 15.8484 80.5507 13.9385C80.5507 11.8226 81.2432 10.1317 82.6295 8.86828C84.3742 7.27718 86.7692 6.69733 89.0848 7.03398C90.2893 7.20934 91.5732 7.674 92.4756 8.51883C93.2821 9.27405 93.9771 10.4645 93.5944 11.5948C93.5675 11.6729 93.5368 11.7497 93.5009 11.8239C92.98 12.9055 91.4951 12.9017 90.5786 12.373C89.2781 11.6229 89.0259 10.0792 88.1363 8.98093C87.7804 8.54059 87.3772 8.32042 86.9279 8.32042C86.3468 8.33962 85.8744 8.64811 85.5096 9.24717C85.1448 9.84624 84.9617 10.5861 84.9617 11.4668C84.9617 13.3024 85.3778 14.7719 86.2123 15.8778C87.0457 16.9825 88.1555 17.5355 89.5417 17.5355C90.265 17.5355 90.9728 17.3409 91.5783 16.9415C91.9022 16.729 92.1902 16.4654 92.4436 16.1722C92.6843 15.8957 92.9032 15.5604 93.3294 15.6615C93.4318 15.6858 93.5291 15.737 93.5995 15.8164C93.7032 15.9341 93.7326 16.1005 93.7301 16.2567C93.7006 17.547 93.0696 18.7285 92.1274 19.5848C91.097 20.5218 89.6736 20.989 87.8572 20.989H87.8547Z"
                  fill="black"
                />
                <path
                  d="M91.5412 12.3398C91.6189 12.3398 91.682 12.2767 91.682 12.199C91.682 12.1212 91.6189 12.0582 91.5412 12.0582C91.4634 12.0582 91.4004 12.1212 91.4004 12.199C91.4004 12.2767 91.4634 12.3398 91.5412 12.3398Z"
                  fill="black"
                />
                <path
                  d="M17.351 4.69149C17.351 4.69149 17.351 4.6851 17.351 4.68254L16.8569 1.25457C16.8006 0.674708 16.4819 0.384139 15.902 0.384139H1.46053C0.879388 0.384139 0.561942 0.674708 0.50562 1.25457L0.011516 4.68254C0.011516 4.68254 0.011516 4.68893 0.011516 4.69149C0.00511581 4.73885 0 4.7875 0 4.83614C0 5.41728 0.471053 5.88834 1.05219 5.88834C1.41573 5.88834 1.73574 5.70401 1.92519 5.42368C2.19784 5.02943 2.3476 4.65949 2.60617 4.19867C2.83402 3.7929 3.15019 3.37817 3.49709 3.02232C3.84398 2.66646 4.23183 2.48853 4.66321 2.48853H6.1519V17.6635C6.1519 17.6712 6.1519 17.6776 6.1519 17.6878C6.1519 18.9282 5.08306 19.3378 4.95633 19.9791C4.94865 20.0188 4.94354 20.0597 4.94354 20.1032C4.94354 20.4373 5.21362 20.7074 5.54771 20.7074H11.8161C12.1502 20.7074 12.4203 20.4373 12.4203 20.1032C12.4203 20.061 12.4152 20.02 12.4075 19.9791C12.2808 19.3391 11.2119 18.9294 11.2119 17.6878C11.2119 17.2807 11.2119 2.48982 11.2119 2.48982H12.7006C13.132 2.48982 13.5198 2.66774 13.8667 3.02359C14.2136 3.37945 14.5298 3.79418 14.7576 4.19996C15.0162 4.66077 15.166 5.02943 15.4386 5.42496C15.6281 5.70529 15.9481 5.88962 16.3116 5.88962C16.8928 5.88962 17.3638 5.41856 17.3638 4.83742C17.3638 4.7875 17.3587 4.74014 17.3523 4.69277L17.351 4.69149Z"
                  fill="black"
                />
                <path
                  d="M33.5628 6.16995C35.2383 6.16995 36.5965 4.81171 36.5965 3.13624C36.5965 1.46077 35.2383 0.102527 33.5628 0.102527C31.8873 0.102527 30.5291 1.46077 30.5291 3.13624C30.5291 4.81171 31.8873 6.16995 33.5628 6.16995Z"
                  fill="black"
                />
                <path
                  d="M37.5373 19.9791C37.4106 19.3391 36.3418 18.9294 36.3418 17.6878V7.67272C36.3418 7.37319 36.2291 7.15814 36.0051 7.0263C35.7798 6.89573 35.4713 6.91365 35.0784 7.08262L30.8401 9.15885C30.3102 9.4187 30.154 10.101 30.5188 10.5656C30.5982 10.6667 30.6776 10.7679 30.7518 10.8613C31.1205 11.3247 31.3137 11.7932 31.3137 12.2796V17.6878C31.3137 18.9282 30.2436 19.3378 30.1169 19.9791C30.1092 20.0188 30.1041 20.0597 30.1041 20.1032C30.1041 20.4373 30.3742 20.7074 30.7083 20.7074H31.3125V20.6985C31.3125 20.6985 31.3176 20.7074 31.3227 20.7074H36.3315C36.3315 20.7074 36.3418 20.7023 36.3418 20.6972V20.7074H36.9459C37.28 20.7074 37.5501 20.4373 37.5501 20.1032C37.5501 20.061 37.545 20.02 37.5373 19.9791Z"
                  fill="black"
                />
                <path
                  d="M111.523 19.6066C111.361 19.4095 111.144 19.261 110.943 19.1112C110.465 18.7554 110.258 18.4789 110.165 18.347L105.642 11.8853C105.651 11.8777 105.66 11.8713 105.669 11.8636C106.071 11.5346 106.473 11.2056 106.875 10.8754C107.498 10.3647 108.12 9.85392 108.742 9.3419C108.973 9.15117 109.203 8.96172 109.434 8.771C109.747 8.50987 110.065 8.23338 110.085 7.7956C110.09 7.68552 110.075 7.5716 110.02 7.47687C109.901 7.27591 109.66 7.22726 109.445 7.22599L105.522 7.20678C105.13 7.20678 104.644 7.1287 104.431 7.45767C104.371 7.54984 104.352 7.66376 104.349 7.77384C104.335 8.49067 105.034 8.88364 105.27 9.49807C105.323 9.63759 105.346 9.79376 105.306 9.93712C105.242 10.1637 104.39 10.9087 104.215 11.0584C103.519 11.6562 101.748 13.0937 101.259 13.5136V0.73231C101.259 0.43278 101.147 0.217733 100.923 0.0858887C100.697 -0.044676 100.389 -0.026755 99.9959 0.142211L95.7577 2.21845C95.2277 2.47829 95.0715 3.16056 95.4364 3.62521C95.5157 3.72634 95.5951 3.82746 95.6693 3.92091C96.038 4.38428 96.2313 4.85278 96.2313 5.33919V17.6852C96.23 18.9256 95.1612 19.3352 95.0344 19.9765C95.0267 20.0162 95.0216 20.0572 95.0216 20.1007C95.0216 20.4348 95.2917 20.7049 95.6258 20.7049H101.863C102.198 20.7049 102.468 20.4348 102.468 20.1007C102.468 20.0584 102.463 20.0175 102.455 19.9765C102.328 19.3365 101.259 18.9269 101.259 17.6852V15.9572L102.663 14.6656C102.663 14.6656 105.135 18.3445 105.135 18.3457C105.451 18.8168 104.91 19.2392 104.694 19.6117C104.58 19.8088 104.505 20.0418 104.552 20.2658C104.618 20.5845 104.893 20.7049 105.19 20.7049H110.627C110.943 20.6997 111.431 20.7356 111.619 20.5269C111.684 20.4553 111.721 20.3605 111.736 20.2645C111.772 20.029 111.672 19.7884 111.521 19.604L111.523 19.6066Z"
                  fill="black"
                />
                <path
                  d="M25.9209 6.94309C24.3145 6.94309 22.9026 7.94921 22.2446 9.72464V7.66632C22.2446 7.36679 22.132 7.15174 21.908 7.0199C21.6827 6.88933 21.3742 6.90725 20.9812 7.07622L17.5149 9.16013C16.9849 9.41998 16.8288 10.1022 17.1936 10.5669C17.2729 10.668 17.3523 10.7691 17.4265 10.8626C17.7952 11.326 17.9885 11.7945 17.9885 12.2809V17.6891C17.9885 18.9294 16.9196 19.3391 16.7929 19.9804C16.7852 20.02 16.7801 20.061 16.7801 20.1045C16.7801 20.4386 17.0502 20.7087 17.3843 20.7087H17.9885V20.6985C17.9885 20.6985 17.9923 20.7087 17.9987 20.7087H23.357C23.6911 20.7087 23.9612 20.4386 23.9612 20.1045C23.9612 20.0623 23.9561 20.0213 23.9484 19.9804C23.8332 19.3992 22.9435 19.0062 22.781 18.0104L22.7528 17.6904V12.423C22.7528 10.6258 23.0984 9.72591 23.7922 9.72591C24.0917 9.72591 24.3158 9.86159 24.4668 10.133C24.6166 10.4043 24.6601 10.6949 24.7945 11.029C24.9212 11.3452 25.0633 11.5717 25.3334 11.8175C25.6547 12.1094 26.1321 12.35 26.7133 12.35C27.7834 12.35 28.8548 11.5052 28.8548 9.87312C28.8548 8.24106 27.5952 6.94437 25.9248 6.94437L25.9209 6.94309Z"
                  fill="black"
                />
                <path
                  d="M53.639 8.8094C52.4857 7.50375 50.974 6.85093 49.1064 6.85093C48.172 6.85093 47.1466 7.04806 46.3927 7.6484C46.0855 7.89289 45.7271 8.29482 45.553 8.69164V7.5716C45.553 7.27206 45.4403 7.05702 45.2163 6.92517C44.991 6.79461 44.6825 6.81253 44.2896 6.9815L40.0513 9.05901C39.5214 9.31886 39.3652 10.0011 39.7301 10.4645C39.8094 10.5669 39.8888 10.6667 39.963 10.7602C40.3317 11.2236 40.525 11.6921 40.525 12.1785L40.5301 23.617C40.5301 24.8573 39.4612 25.267 39.3345 25.9083C39.3268 25.9479 39.3217 25.9889 39.3217 26.0324C39.3217 26.3665 39.5918 26.6366 39.9259 26.6366H46.152C46.4861 26.6366 46.7562 26.3665 46.7562 26.0324C46.7562 25.9902 46.7511 25.9492 46.7434 25.9083C46.6167 25.2695 45.5517 24.8599 45.5479 23.6247C45.5504 20.3362 45.553 11.4962 45.553 11.4962C45.6067 10.6514 45.7514 10.2494 45.9408 9.94224C46.3005 9.35982 46.9047 9.02573 47.6766 9.02573C48.515 9.02573 49.1781 9.4379 49.6645 10.2622C50.1509 11.0866 50.3941 12.2143 50.3941 13.6441C50.3941 15.1572 50.1931 18.0283 48.3601 18.5505C47.7355 18.7285 47.0647 18.2305 46.5783 18.8731C46.3709 19.1483 46.3325 19.5413 46.4849 19.8511C46.6743 20.2351 47.0954 20.4412 47.5025 20.5743C49.5506 21.2425 52.0953 20.6473 53.5686 19.0421C54.7565 17.748 55.3709 16.0609 55.3709 13.9437C55.3709 11.8265 54.7936 10.1163 53.6403 8.81068L53.639 8.8094Z"
                  fill="black"
                />
                <path
                  d="M71.1718 4.69149C71.1718 4.69149 71.1718 4.6851 71.1718 4.68254L70.6777 1.25457C70.6214 0.674708 70.3027 0.384139 69.7228 0.384139H55.2813C54.7002 0.384139 54.3827 0.674708 54.3264 1.25457L53.8323 4.68254C53.8323 4.68254 53.8323 4.68893 53.8323 4.69149C53.8259 4.73885 53.8208 4.7875 53.8208 4.83614C53.8208 5.41728 54.2919 5.88834 54.873 5.88834C55.2365 5.88834 55.5565 5.70401 55.746 5.42368C56.0186 5.02943 56.1684 4.65949 56.427 4.19867C56.6548 3.7929 56.971 3.37817 57.3179 3.02232C57.6648 2.66646 58.0526 2.48853 58.484 2.48853H59.9727V17.6635C59.9727 17.6712 59.9727 17.6776 59.9727 17.6878C59.9727 18.9282 58.9039 19.3378 58.7771 19.9791C58.7695 20.0188 58.7643 20.0597 58.7643 20.1032C58.7643 20.4373 59.0344 20.7074 59.3685 20.7074H65.6369C65.971 20.7074 66.2411 20.4373 66.2411 20.1032C66.2411 20.061 66.236 20.02 66.2283 19.9791C66.1016 19.3391 65.0327 18.9294 65.0327 17.6878C65.0327 17.2807 65.0327 2.48982 65.0327 2.48982H66.5214C66.9528 2.48982 67.3406 2.66774 67.6875 3.02359C68.0344 3.37945 68.3506 3.79418 68.5784 4.19996C68.837 4.66077 68.9868 5.02943 69.2594 5.42496C69.4489 5.70529 69.7689 5.88962 70.1324 5.88962C70.7136 5.88962 71.1846 5.41856 71.1846 4.83742C71.1846 4.7875 71.1795 4.74014 71.1731 4.69277L71.1718 4.69149Z"
                  fill="black"
                />
                <path
                  d="M75.6033 6.16995C77.2788 6.16995 78.637 4.81171 78.637 3.13624C78.637 1.46077 77.2788 0.102527 75.6033 0.102527C73.9279 0.102527 72.5696 1.46077 72.5696 3.13624C72.5696 4.81171 73.9279 6.16995 75.6033 6.16995Z"
                  fill="black"
                />
                <path
                  d="M79.5779 19.9791C79.4512 19.3391 78.3823 18.9294 78.3823 17.6878V7.67272C78.3823 7.37319 78.2697 7.15814 78.0457 7.0263C77.8204 6.89573 77.5119 6.91365 77.1189 7.08262L72.8807 9.15885C72.3507 9.4187 72.1946 10.101 72.5594 10.5656C72.6388 10.6667 72.7181 10.7679 72.7924 10.8613C73.161 11.3247 73.3543 11.7932 73.3543 12.2796V17.6878C73.3543 18.9282 72.2842 19.3378 72.1575 19.9791C72.1498 20.0188 72.1447 20.0597 72.1447 20.1032C72.1447 20.4373 72.4147 20.7074 72.7488 20.7074H73.353V20.6985C73.353 20.6985 73.3581 20.7074 73.3633 20.7074H78.3721C78.3721 20.7074 78.3823 20.7023 78.3823 20.6972V20.7074H78.9865C79.3206 20.7074 79.5907 20.4373 79.5907 20.1032C79.5907 20.061 79.5856 20.02 79.5779 19.9791Z"
                  fill="black"
                />
              </svg>
            </div>

          </div>
        </div>
      </header> 
      <ToastContainer />
      <div className="reset auth-form">
        <div className="auth-container">
          <h1>Reset Password</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                  <>
                    <div className="input-container">
                      <label htmlFor="password">Contraseña:</label>
                      <Field type="password" id="password" name="password" />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="confirm_password">
                      Confirmar contraseña:
                      </label>
                      <Field
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <button
                      type="submit"
                      className="join-btn"
                      disabled={isSubmitting}
                    >
                      Enviar
                    </button>
                  </>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
