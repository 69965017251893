import React from "react";
import { useRef, useState, useEffect } from "react";
import "./mapboxMapForMyTrips.css";

import hotel from "../../assets/images/hotel.svg";
import restaurant from "../../assets/images/restaurant.svg";
import cafe from "../../assets/images/cafe.svg";
import pub from "../../assets/images/pub.svg";
import bar from "../../assets/images/pub.svg";
import buffet from "../../assets/images/buffet.svg";
import bakery from "../../assets/images/bakery.svg";
import teahouse from "../../assets/images/tea house.svg";
import steakhouse from "../../assets/images/steakhouse.svg";
import motel from "../../assets/images/motel.svg";
import lodge from "../../assets/images/lodge.svg";
import hostel from "../../assets/images/hostel.svg";
import barbershop from "../../assets/images/barbershop.svg";
import building from "../../assets/images/building.svg";
import gym from "../../assets/images/gym.svg";
import market from "../../assets/images/market.svg";
import office from "../../assets/images/office.svg";
import resort from "../../assets/images/resort.svg";
import stadium from "../../assets/images/stadium.svg";
import burger from "../../assets/images/burger joint.svg";
import bistro from "../../assets/images/bistro.svg";
import breakfast from "../../assets/images/breakfast place.svg";
import chocolate from "../../assets/images/chocolate shop.svg";
import coffee from "../../assets/images/coffee shop.svg";
import eatry from "../../assets/images/eatery.svg";
import pizzeria from "../../assets/images/pizzeria.svg";
import plaza from "../../assets/images/plaza.svg";
import seafood from "../../assets/images/seafood restaurant.svg";
import smoothie from "../../assets/images/smoothie bar.svg";
import supermarket from "../../assets/images/supermarket.svg";
import sushi from "../../assets/images/sushi.svg"
import tea from "../../assets/images/tea house.svg"
import vegan from "../../assets/images/vegan restaurant.svg"










import location from "../../assets/images/location.svg";

import "../nearbyplaces/nearByPlaces.css";
import { BASEURL } from "../../api";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  "pk.eyJ1IjoianVha29wZWRyZW5vIiwiYSI6ImNrZXZxNjIyMzByM3kyem1lY2FmODB0eTEifQ.zPhuiJXX6b7UYkbyFZMDlw";

const MapboxMapForMyTrips = ({
  myTrips
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(0.35);
  const [
    differentLocationsMapCoordinates,
    setDifferentLocationsMapCoordinates,
  ] = useState([]);

  const [newArray, setNewArray] = useState([])



  

  // useEffect(() => {
  //   // console.log("dataToSendToMapboxForMarkers23232323",dataToSendToMapboxForMarkers);
  //   if(newArray.length > 0){
  //     console.log("newArray",newArray);
  //   }
    
  // }, [newArray,dataToSendToMapboxForMarkers])
  


  const createDefaultMarkerElement = () => {
    // Assuming `nameToSendForMarkerType` is a variable containing the marker type
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.innerHTML = `<div class="map-marker-wrapper"><img src="${location}" alt="Default Marker Icon" height="30px" width="30px" /></div>`;
    return el;
  };



  useEffect(() => {
    if (!map.current) {
      // console.log("uuuuuuuuuuuuuuuuuuuuuuu");
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
      });

      map.current.on("move", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      // Set marker options.
      function getRandomOffset() {
        // Generate random offsets within a specified range
        const offsetRange = 0.02; // Adjust this value based on your requirements
        const randomOffsetLng = (Math.random() - 0.5) * offsetRange;
        const randomOffsetLat = (Math.random() - 0.5) * offsetRange;
      
        return [randomOffsetLng, randomOffsetLat];
      }


      if (
        myTrips.length > 0
      ) {

            myTrips.forEach((item,idx) => {
              const [offsetLng, offsetLat] = getRandomOffset();

              const marker = new mapboxgl.Marker({
                element: createDefaultMarkerElement(),
                draggable: false,
              })
                .setLngLat([
                  parseFloat(item.coordinatesOfItinerary.lng)+ offsetLng,
                  parseFloat(item.coordinatesOfItinerary.lat)+ offsetLat,
                ])
                .addTo(map.current);
            
          });
        
      }

      // map.current.addControl(
      //   new mapboxgl.FullscreenControl({
      //     container: document.querySelector("body"),
      //   })
      // );

      map.current.addControl(new mapboxgl.NavigationControl());

    }
  }, [
    lng,
    lat,
    zoom,
    myTrips,
  ]);

  useEffect(() => {
    if (
      myTrips.length > 0
    ) {
      // console.log("nnnnnnnnnnnnnnnnnnnnnnnn", placeNameZoomedInOnLoad);

      map.current.flyTo({
        bearing: 90,
        center: [
          myTrips[0].coordinatesOfItinerary.lng,
          myTrips[0].coordinatesOfItinerary.lat,
        ],
        zoom: 2,
        pitch: 40,
        duration: 9000,
      });

    } else {
      // pass
    }
  }, [myTrips]);



  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  //   useEffect(() => {
  //     if (differentLocationsMapCoordinates.length > 0 && map.current) {
  //       map.current.flyTo(differentLocationsMapCoordinates[activePlaceOnMap]);
  //       console.log("Flying to a new location");
  //     }
  //   }, [activePlaceOnMap, differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log(
  //     "findCurrentData(activePlaceOnMap)",
  //     findCurrentData(activePlaceOnMap)
  //   );
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log("activePlaceOnMap", activePlaceOnMap);
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers)
  // }, [dataToSendToMapboxForMarkers])

  // useEffect(() => {
  //   console.log("searchedPlaceDetailObject",searchedPlaceDetailObject)
  // }, [searchedPlaceDetailObject])

  return (
    <div>
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default MapboxMapForMyTrips;
