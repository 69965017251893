import React from 'react'
import { BASEURL } from '../../api';
// import "./Card.css"
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

const Card = ({ property_images, alt = 'Image', property_price, capacity, property_link }) => {
    // useEffect(() => {
      // console.log("property_link",property_link,property_images)
    // }, [property_link,property_images])
    

  return (
    <>
    <div className="card">
      <div className='card-image'>
        <div className='card-image-overlay'></div>
      <img src={`${BASEURL}${property_images}`} alt={alt} />
<div className='view-more-button'>
<Link to={property_link} target="_blank" rel="noopener noreferrer">View More <span className='right-arrow'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M8.47075 3.36193C8.2104 3.10158 7.78829 3.10158 7.52794 3.36193C7.26759 3.62228 7.26759 4.04439 7.52794 4.30474L11.0565 7.83333H3.33268C2.96449 7.83333 2.66602 8.13181 2.66602 8.5C2.66602 8.86819 2.96449 9.16666 3.33268 9.16666H11.0565L7.52794 12.6953C7.26759 12.9556 7.26759 13.3777 7.52794 13.6381C7.78829 13.8984 8.2104 13.8984 8.47075 13.6381L13.1374 8.9714C13.3978 8.71105 13.3978 8.28894 13.1374 8.02859L8.47075 3.36193Z" fill="#F2ECE2"/>
</svg></span>
            </Link >
</div>
      </div>
    <div className="card-content">
        <div className="card-left">
        <p className="card-heading1"><span className='card-left-icon'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_596_1626)">
    <path opacity="0.4" d="M9.02031 5.5679C8.6387 5.83996 8.1717 6 7.66732 6C6.37865 6 5.33398 4.95533 5.33398 3.66667C5.33398 2.378 6.37865 1.33333 7.66732 1.33333C8.50264 1.33333 9.23545 1.77228 9.64766 2.43209M13.334 4.33333C13.334 5.622 12.2893 6.66667 11.0007 6.66667C9.71199 6.66667 8.66732 5.622 8.66732 4.33333C8.66732 3.04467 9.71199 2 11.0007 2C12.2893 2 13.334 3.04467 13.334 4.33333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.00065 13.3914H5.74084C5.96774 13.3914 6.19324 13.4184 6.41319 13.4724L8.25191 13.9193C8.65088 14.0164 9.0665 14.0259 9.46963 13.9476L11.5026 13.5521C12.0397 13.4475 12.5337 13.1903 12.9209 12.8137L14.3592 11.4145C14.77 11.0156 14.77 10.3683 14.3592 9.96871C13.9894 9.60896 13.4038 9.56846 12.9854 9.87354L11.309 11.0966C11.069 11.2721 10.7769 11.3666 10.4764 11.3666H8.85765L9.88803 11.3665C10.4688 11.3665 10.9392 10.9089 10.9392 10.3439V10.1394C10.9392 9.67033 10.611 9.26131 10.1434 9.14791L8.55305 8.76116C8.29424 8.69839 8.02919 8.66667 7.76274 8.66667C7.11954 8.66667 5.95525 9.19921 5.95525 9.19921L4.00065 10.0166M1.33398 9.73333L1.33398 13.6C1.33398 13.9734 1.33398 14.1601 1.40665 14.3027C1.47056 14.4281 1.57255 14.5301 1.69799 14.594C1.8406 14.6667 2.02728 14.6667 2.40065 14.6667H2.93398C3.30735 14.6667 3.49404 14.6667 3.63664 14.594C3.76209 14.5301 3.86407 14.4281 3.92799 14.3027C4.00065 14.1601 4.00065 13.9734 4.00065 13.6V9.73333C4.00065 9.35997 4.00065 9.17328 3.92799 9.03067C3.86407 8.90523 3.76209 8.80325 3.63664 8.73933C3.49404 8.66667 3.30735 8.66667 2.93398 8.66667L2.40065 8.66667C2.02728 8.66667 1.8406 8.66667 1.69799 8.73933C1.57255 8.80325 1.47056 8.90523 1.40665 9.03067C1.33398 9.17328 1.33398 9.35997 1.33398 9.73333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_596_1626">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg></span>Desde {property_price}€ / persona</p>
      <p className="card-heading2"><span className='card-left-icon'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path opacity="0.4" d="M11.9993 10.5579C12.9699 11.0455 13.8021 11.828 14.4095 12.8064C14.5298 13.0002 14.5899 13.0971 14.6107 13.2312C14.653 13.5038 14.4666 13.839 14.2126 13.9469C14.0877 14 13.9471 14 13.666 14M10.666 7.68816C11.6538 7.19726 12.3327 6.1779 12.3327 5C12.3327 3.82209 11.6538 2.80274 10.666 2.31184" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.33322 8C7.99008 8 9.33322 6.65685 9.33322 5C9.33322 3.34315 7.99008 2 6.33322 2C4.67637 2 3.33322 3.34315 3.33322 5C3.33322 6.65685 4.67637 8 6.33322 8Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.33322 10C4.44614 10 2.76892 11.0297 1.70604 12.6256C1.4732 12.9752 1.35678 13.15 1.37018 13.3733C1.38062 13.5471 1.49459 13.76 1.63352 13.8651C1.81194 14 2.05734 14 2.54813 14H10.1183C10.6091 14 10.8545 14 11.0329 13.8651C11.1719 13.76 11.2858 13.5471 11.2963 13.3733C11.3097 13.15 11.1932 12.9752 10.9604 12.6256C9.89753 11.0297 8.22031 10 6.33322 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>{capacity} personas</p>
        </div>

      
    </div>
  </div>

  </>
  )
}

export default Card