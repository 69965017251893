/* eslint-disable react/prop-types */
import React from "react";
import "./Modal.css";
import { useState, useEffect } from "react";
import swal from "sweetalert";

const MyModal = ({
  countryNameChoose,
  isModalActive,
  setIsModalActive,
  BASEURL,
  setoverlayLoaderStyleState,
  setCountryValueState,
  countryValueState,
  countryNameState,
  setvisitedCountryCountData,
  setwishlistCountryCountData,
  setvisitedCountryPercenatge,
  isVisitedCountry,
  setIsVisitedCountry,
  isAddedToWishlist,
  setIsAddedToWishlist,
  makeButtonDisable,
  setMakeButtonDisable
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsModalActive(false);
  };

  function findKeyByValue(object, value) {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  }

  const addToWishlist = async () => {

      try {
        setoverlayLoaderStyleState(true);
        const bearerToken = localStorage.getItem("token");
        const response = await fetch(`${BASEURL}/add-to-wishlist`, {
          method: "POST", // or "GET" or any other HTTP method you need
          headers: {
            "Content-Type": "application/json", // Set the appropriate content type
            Authorization: `Bearer ${bearerToken}`,
          },
          // You can add a request body if needed, e.g., JSON data
          body: JSON.stringify({ country: countryNameChoose }),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.status == "success") {
            let added_country_code = findKeyByValue(
              countryNameState,
              countryNameChoose
            );
            if (added_country_code != null) {
              countryValueState[added_country_code] = 50;
              // setAddOrRemoveToWishlistButton("Remove from");
              setCountryValueState(countryValueState);
              setvisitedCountryCountData(data.visited_country_count);
              setwishlistCountryCountData(data.wishlist_country_count);
              setvisitedCountryPercenatge(data.visited_country_percenatge);
            }
          }
          if (data.status == "error") {
            swal("", data.message, "error");
          } else if (data.status == "success") {
            swal("", data.message, "success");
          } else {
            swal("", data.message, "warning");
          }
          closeModal();
        } else {
          // Handle error responses here
          console.error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        // Regardless of success or failure, hide the overlay
        setoverlayLoaderStyleState(false);
      }

      setIsAddedToWishlist(true);
      setMakeButtonDisable(false);
      setIsVisitedCountry(false)
    
  };

  const removeFromWishlist = async () => {

      try {
        setoverlayLoaderStyleState(true);
        const bearerToken = localStorage.getItem("token");
        const response = await fetch(
          `${BASEURL}/remove-country-from-user-wishlist`,
          {
            method: "DELETE", // or "GET" or any other HTTP method you need
            headers: {
              "Content-Type": "application/json", // Set the appropriate content type
              Authorization: `Bearer ${bearerToken}`,
            },
            // You can add a request body if needed, e.g., JSON data
            body: JSON.stringify({ country: countryNameChoose }),
          }
        );

        if (response.ok) {

          const data = await response.json();

          if (data.status == "success") {

            let added_country_code = findKeyByValue(
              countryNameState,
              countryNameChoose
            );
            if (added_country_code != null) {
              countryValueState[added_country_code] = 0;
              // setAddOrRemoveToWishlistButton("Add to");
              setCountryValueState(countryValueState);
              setvisitedCountryCountData(data.visited_country_count);
              setwishlistCountryCountData(data.wishlist_country_count);
              setvisitedCountryPercenatge(data.visited_country_percenatge);
            }
          }
          if (data.status == "error") {
            swal("", data.message, "error");
          } else if (data.status == "success") {
            swal("", data.message, "success");
          } else {
            swal("", data.message, "warning");
          }
          closeModal();
        } else {
          // Handle error responses here
          console.error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        // Regardless of success or failure, hide the overlay
        setoverlayLoaderStyleState(false);
      }

      setIsAddedToWishlist(false);
      setMakeButtonDisable(false);
      setIsVisitedCountry(false)
    
  };

  const handleVisitedClickButton = async () => {
    try {
      setoverlayLoaderStyleState(true);
      const bearerToken = localStorage.getItem("token");
      const response = await fetch(`${BASEURL}/add-to-visited-countries`, {
        method: "POST", // or "GET" or any other HTTP method you need
        headers: {
          "Content-Type": "application/json", // Set the appropriate content type
          Authorization: `Bearer ${bearerToken}`,
        },
        // You can add a request body if needed, e.g., JSON data
        body: JSON.stringify({ country: countryNameChoose }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status == "success") {
          let added_country_code = findKeyByValue(
            countryNameState,
            countryNameChoose
          );
          if (added_country_code != null) {
            countryValueState[added_country_code] = 100;
            setCountryValueState(countryValueState);
            setvisitedCountryCountData(data.visited_country_count);
            setwishlistCountryCountData(data.wishlist_country_count);
            setvisitedCountryPercenatge(data.visited_country_percenatge);
          }
        }
        if (data.status == "error") {
          swal("", data.message, "error");
        } else if (data.status == "success") {
          swal("", data.message, "success");
        } else {
          swal("", data.message, "warning");
        }
        closeModal();
      } else {
        // Handle error responses here
        console.error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      // Regardless of success or failure, hide the overlay
      setoverlayLoaderStyleState(false);
    }
    setIsVisitedCountry(true);
    setIsAddedToWishlist(true);
    setMakeButtonDisable(true);
  };

  const handleRemoveFromVisitedClickButton = async () => {
    try {
      setoverlayLoaderStyleState(true);
      const bearerToken = localStorage.getItem("token");
      const response = await fetch(`${BASEURL}/remove-country-from-user-visited-countries`, {
        method: "DELETE", // or "GET" or any other HTTP method you need
        headers: {
          "Content-Type": "application/json", // Set the appropriate content type
          Authorization: `Bearer ${bearerToken}`,
        },
        // You can add a request body if needed, e.g., JSON data
        body: JSON.stringify({ country: countryNameChoose }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status == "success") {
          let added_country_code = findKeyByValue(
            countryNameState,
            countryNameChoose
          );
          if (added_country_code != null) {
            countryValueState[added_country_code] = 0;
            setCountryValueState(countryValueState);
            setvisitedCountryCountData(data.visited_country_count);
            setwishlistCountryCountData(data.wishlist_country_count);
            setvisitedCountryPercenatge(data.visited_country_percenatge);
          }
        }
        if (data.status == "error") {
          swal("", data.message, "error");
        } else if (data.status == "success") {
          swal("", data.message, "success");
        } else {
          swal("", data.message, "warning");
        }
        closeModal();
      } else {
        // Handle error responses here
        console.error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      // Regardless of success or failure, hide the overlay
      setoverlayLoaderStyleState(false);
    }

    setIsVisitedCountry(false);
    setIsAddedToWishlist(false);
    setMakeButtonDisable(false);

  };

  return (
    <div
      className="custom-modal map-custom-modal"
      style={{ display: isModalActive ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">¿Quiero visitar {countryNameChoose}?</h5>
            <button type="button" className="close" onClick={closeModal}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-footer">
            {/* visited country button */}
            {isVisitedCountry ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleRemoveFromVisitedClickButton}
              >
                Quitar de Visitado
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleVisitedClickButton}
              >
                Visitada
              </button>
            )}

            {/* wishlist country button */}
            {isAddedToWishlist ? (
              <button
                type="button"
                className={`btn btn-primary ${makeButtonDisable && "makedisable"}`}
                onClick={removeFromWishlist}
                disabled={makeButtonDisable}
                
              >
                Eliminar de la lista de deseos
              </button>
            ) : (
              <button
                type="button"
                className={`btn btn-primary ${makeButtonDisable && "makedisable"}`}
                onClick={addToWishlist}
                disabled={makeButtonDisable}
              >
                Añadir a la lista de deseos
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyModal;
