import React from "react";
import { useRef, useState, useEffect } from "react";
import "./mapboxMapForProperties.css";
import { BASEURL } from "../../api";
import hotel from "../../assets/images/hotel.svg";
import restaurant from "../../assets/images/restaurant.svg";
import cafe from "../../assets/images/cafe.svg";
import pub from "../../assets/images/pub.svg";
import bar from "../../assets/images/pub.svg";
import buffet from "../../assets/images/buffet.svg";
import bakery from "../../assets/images/bakery.svg";
import teahouse from "../../assets/images/tea house.svg";
import steakhouse from "../../assets/images/steakhouse.svg";
import motel from "../../assets/images/motel.svg";
import lodge from "../../assets/images/lodge.svg";
import hostel from "../../assets/images/hostel.svg";
import barbershop from "../../assets/images/barbershop.svg";
import building from "../../assets/images/building.svg";
import gym from "../../assets/images/gym.svg";
import market from "../../assets/images/market.svg";
import office from "../../assets/images/office.svg";
import resort from "../../assets/images/resort.svg";
import stadium from "../../assets/images/stadium.svg";
import burger from "../../assets/images/burger joint.svg";
import bistro from "../../assets/images/bistro.svg";
import breakfast from "../../assets/images/breakfast place.svg";
import chocolate from "../../assets/images/chocolate shop.svg";
import coffee from "../../assets/images/coffee shop.svg";
import eatry from "../../assets/images/eatery.svg";
import pizzeria from "../../assets/images/pizzeria.svg";
import plaza from "../../assets/images/plaza.svg";
import seafood from "../../assets/images/seafood restaurant.svg";
import smoothie from "../../assets/images/smoothie bar.svg";
import supermarket from "../../assets/images/supermarket.svg";
import sushi from "../../assets/images/sushi.svg"
import tea from "../../assets/images/tea house.svg"
import vegan from "../../assets/images/vegan restaurant.svg"


import location from "../../assets/images/location.svg";

import "../nearbyplaces/nearByPlaces.css";


import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  "pk.eyJ1IjoianVha29wZWRyZW5vIiwiYSI6ImNrZXZxNjIyMzByM3kyem1lY2FmODB0eTEifQ.zPhuiJXX6b7UYkbyFZMDlw";

const MapboxMapForProperties = ({
  data
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(0.35);
  const [
    differentLocationsMapCoordinates,
    setDifferentLocationsMapCoordinates,
  ] = useState([]);

  const [newArray, setNewArray] = useState([])



  

  // useEffect(() => {
  //   // console.log("dataToSendToMapboxForMarkers23232323",dataToSendToMapboxForMarkers);
  //   if(newArray.length > 0){
  //     console.log("newArray",newArray);
  //   }
    
  // }, [newArray,dataToSendToMapboxForMarkers])
  


  const createDefaultMarkerElement = () => {
    // Assuming `nameToSendForMarkerType` is a variable containing the marker type
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.innerHTML = `<div class="map-marker-wrapper"><svg class="home-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="594.657px" height="832.35px" viewBox="0 0 594.657 832.35" enable-background="new 0 0 594.657 832.35" xml:space="preserve">
    <path fill="#FFCD0A" d="M507.572,87.086C451.414,30.928,376.748,0,297.329,0S143.244,30.928,87.086,87.086S0,217.91,0,297.33
        c0,37.328,8.104,75.127,24.773,115.561c13.006,31.545,31.131,64.504,57.041,103.725l82.887,125.467l113.352,180.572
        c4.189,6.676,11.396,10.66,19.276,10.66c7.881,0,15.087-3.984,19.276-10.66l113.319-180.521l82.919-125.518
        c25.911-39.221,44.035-72.18,57.041-103.725c16.67-40.434,24.772-78.232,24.772-115.561
        C594.657,217.91,563.729,143.244,507.572,87.086z" class="pin-color"></path>
    <path fill="#010101" d="M457.477,262.824L303.686,139.79c-4.277-2.983-8.552-2.983-12.817,0l-55.108,44.215v-25.632
        c0-5.547-2.033-10.352-6.086-14.418c-4.066-4.056-8.872-6.088-14.419-6.088c-5.557,0-10.362,2.032-14.418,6.088
        c-4.065,4.066-6.088,8.871-6.088,14.418v58.313l-57.672,46.139c-5.126,4.274-5.657,9.08-1.602,14.418
        c4.056,5.345,8.86,6.086,14.418,2.241l24.349-19.863v144.818c0,5.547,2.024,10.354,6.089,14.418
        c4.055,4.056,8.86,6.089,14.418,6.089h61.517c5.547,0,10.353-2.033,14.418-6.089c4.056-4.063,6.087-8.871,6.087-14.418v-82.021
        h41.013v82.021c0,5.547,2.022,10.354,6.086,14.418c4.056,4.056,8.86,6.089,14.418,6.089h61.517c5.547,0,10.353-2.033,14.418-6.089
        c4.056-4.063,6.089-8.871,6.089-14.418V259.62l24.349,19.863c1.702,1.281,3.845,1.924,6.408,1.924c3.415,0,6.188-1.283,8.33-3.845
        C463.242,272.016,462.603,267.099,457.477,262.824z" class="house-color"></path>
</svg></div>`;
    return el;
  };






  useEffect(() => {
    if (!map.current) {
      // console.log("uuuuuuuuuuuuuuuuuuuuuuu");
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
      });

      map.current.on("move", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      // Set marker options.
      function getRandomOffset() {
        // Generate random offsets within a specified range
        const offsetRange = 0.02; // Adjust this value based on your requirements
        const randomOffsetLng = (Math.random() - 0.5) * offsetRange;
        const randomOffsetLat = (Math.random() - 0.5) * offsetRange;
      
        return [randomOffsetLng, randomOffsetLat];
      }


      if (
        data.length > 0
      ) {

            data.forEach((items,idx) => {
                items.properties.map((property)=>{
                    const [offsetLng, offsetLat] = getRandomOffset();
              
              const popupContent = document.createElement("div");
              popupContent.innerHTML = `
                <div class="map-marker-popup" style="color:#000">
                <div class="custom-popup">
                <div class="card card-for-property">
                  <img
                    src="${BASEURL}${property.property_images}" width="60px" height="60px"
                  />
                  <div class="card-content">
                    <p class="card-heading1">
                      <b>Price </b>- Desde ${property.property_price}€ /
                      persona
                    </p>
                    <p class="card-heading2">
                      <b>People </b>- ${property.capacity} personas
                    </p>
                  </div>
                </div>
              </div>
                <div>
              `;

              // Add a click event listener to the image
            //   const imgElement = popupContent.querySelector("img");


              const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                popupContent
              );


              const marker = new mapboxgl.Marker({
                element: createDefaultMarkerElement(),
                draggable: false,
              })
                .setLngLat([
                  parseFloat(property.coordinates[1]),
                  parseFloat(property.coordinates[0]),
                ])
                .setPopup(popup)
                .addTo(map.current);
                })
              
            
          });
        
      }

      // map.current.addControl(
      //   new mapboxgl.FullscreenControl({
      //     container: document.querySelector("body"),
      //   })
      // );

      map.current.addControl(new mapboxgl.NavigationControl());

    }
  }, [
    lng,
    lat,
    zoom,
    data,
  ]);

  useEffect(() => {
    if (
      data.length > 0
    ) {
      // console.log("nnnnnnnnnnnnnnnnnnnnnnnn", placeNameZoomedInOnLoad);

      map.current.flyTo({
        bearing: 90,
        center: [
          data[0].properties[0].coordinates[1],
          data[0].properties[0].coordinates[0],
        ],
        zoom: 5,
        pitch: 40,
        duration: 9000,
      });

    } else {
      // pass
    }
  }, [data]);



  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  //   useEffect(() => {
  //     if (differentLocationsMapCoordinates.length > 0 && map.current) {
  //       map.current.flyTo(differentLocationsMapCoordinates[activePlaceOnMap]);
  //       console.log("Flying to a new location");
  //     }
  //   }, [activePlaceOnMap, differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log(
  //     "findCurrentData(activePlaceOnMap)",
  //     findCurrentData(activePlaceOnMap)
  //   );
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log("activePlaceOnMap", activePlaceOnMap);
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers)
  // }, [dataToSendToMapboxForMarkers])

  // useEffect(() => {
  //   console.log("searchedPlaceDetailObject",searchedPlaceDetailObject)
  // }, [searchedPlaceDetailObject])

  return (
    <div>
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default MapboxMapForProperties;
