import React from 'react';
import MyTrips from './MyTrips';
import { useNavigate } from 'react-router-dom';

const ProtectedMyTrips = ({isLoggedIn, setIsLoggedIn}) => {
    const localStoragetoken = localStorage.getItem("token");
    const navigate = useNavigate();
  
    if (!localStoragetoken) {
      navigate('/login');
      window.location.href='/';
      return null; // Return null or any other component if you prefer
    }
  
    // Render the WorldMap component if the "token" exists
    return <MyTrips isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />;

}

export default ProtectedMyTrips