import React from "react";
import { useRef, useState, useEffect } from "react";
import "./mapboxMap.css";

import hotel from "../../assets/images/hotel.svg";
import restaurant from "../../assets/images/restaurant.svg";
import cafe from "../../assets/images/cafe.svg";
import pub from "../../assets/images/pub.svg";
import bar from "../../assets/images/pub.svg";
import buffet from "../../assets/images/buffet.svg";
import bakery from "../../assets/images/bakery.svg";
import teahouse from "../../assets/images/tea house.svg";
import steakhouse from "../../assets/images/steakhouse.svg";
import motel from "../../assets/images/motel.svg";
import lodge from "../../assets/images/lodge.svg";
import hostel from "../../assets/images/hostel.svg";
import barbershop from "../../assets/images/barbershop.svg";
import building from "../../assets/images/building.svg";
import gym from "../../assets/images/gym.svg";
import market from "../../assets/images/market.svg";
import office from "../../assets/images/office.svg";
import resort from "../../assets/images/resort.svg";
import stadium from "../../assets/images/stadium.svg";
import burger from "../../assets/images/burger joint.svg";
import bistro from "../../assets/images/bistro.svg";
import breakfast from "../../assets/images/breakfast place.svg";
import chocolate from "../../assets/images/chocolate shop.svg";
import coffee from "../../assets/images/coffee shop.svg";
import eatry from "../../assets/images/eatery.svg";
import pizzeria from "../../assets/images/pizzeria.svg";
import plaza from "../../assets/images/plaza.svg";
import seafood from "../../assets/images/seafood restaurant.svg";
import smoothie from "../../assets/images/smoothie bar.svg";
import supermarket from "../../assets/images/supermarket.svg";
import sushi from "../../assets/images/sushi.svg";
import tea from "../../assets/images/tea house.svg";
import vegan from "../../assets/images/vegan restaurant.svg";
import worship from "../../assets/images/worship.svg";
import museum from "../../assets/images/museum.svg";
import tourist_attraction from "../../assets/images/tourist_attraction.svg";
import historical_landmark from "../../assets/images/historical_landmark.svg";
import park from "../../assets/images/park.svg";
import amusement_park from "../../assets/images/amusement_park.svg";
import movie_theater from "../../assets/images/movie_theater.svg";
import hindu_temple from "../../assets/images/hindu_temple.svg";












import location from "../../assets/images/location.svg";

import "../nearbyplaces/nearByPlaces.css";
import { BASEURL } from "../../api";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  "pk.eyJ1IjoianVha29wZWRyZW5vIiwiYSI6ImNrZXZxNjIyMzByM3kyem1lY2FmODB0eTEifQ.zPhuiJXX6b7UYkbyFZMDlw";

const MapboxMap = ({
  sizes,
  dataToSendToMapboxForMarkers,
  setDataToSendToMapboxForMarkers,
  activePlaceOnMap,
  setAtFirst,
  atFirst,
  placeNameZoomedInOnLoad,
  nameToSendForMarkerType,
  searchedPlaceDetailObject,
  currentlyClickedPlaceImg,
  setCurrentlyClickedPlaceImg,
  currentOptionChoose,
  setcurrentOptionChoose,
  currentIndex,
  setCurrentIndex,
  entriesArray
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(0.35);
  const [
    differentLocationsMapCoordinates,
    setDifferentLocationsMapCoordinates,
  ] = useState([]);

  const [newArray, setNewArray] = useState([])

  function adjustArray(secondArray) {
    return secondArray.map(item => {
      if (item.placename) {
        // Adjust the placename value to match the format of the first array
        const parts = item.placename.split(',');
        if (parts.length === 3) {
          const [name, city, country] = parts;
          const adjustedName = `${name.replace(' ', '')},${city.trim()},${country.trim()}`;
          return {
            ...item,
            placename: adjustedName
          };
        }
      }
      return item;
    });
  }


  function sortPlacesByPlacename(places) {
    // Sort the array based on the placename property
    places.sort((a, b) => {
        const placenameA = a.placename.toLowerCase();
        const placenameB = b.placename.toLowerCase();

        if (placenameA < placenameB) return -1;
        if (placenameA > placenameB) return 1;
        return 0;
    });

    return places;  // Return the sorted array
}


  useEffect(() => {
    // console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers);
    // console.log("kkkkkk",entriesArray);
    if(dataToSendToMapboxForMarkers.length > 0){
      setNewArray(sortPlacesByPlacename(dataToSendToMapboxForMarkers))
    }
  }, [dataToSendToMapboxForMarkers,entriesArray])
  

  // useEffect(() => {
  //   // console.log("dataToSendToMapboxForMarkers23232323",dataToSendToMapboxForMarkers);
  //   if(newArray.length > 0){
  //     console.log("newArray",newArray);
  //   }
    
  // }, [newArray,dataToSendToMapboxForMarkers])
  

  const bearerToken = localStorage.getItem("token");

  function findCurrentData(hotelName) {
    for (const data of differentLocationsMapCoordinates) {
      const key = Object.keys(data)[0]; // Extract the hotel name (key)
      if (key === hotelName) {
        return data[key]; // Return the data for the specified hotel
      }
    }
    return null; // Return null if the hotel is not found
  }

  /////////////////////////////////// Hashmap /////////////////////////////////////

  const arrayOfImages = {
    restaurant: restaurant,
    cafe: cafe,
    pub: pub,
    bar: bar,
    buffet: buffet,
    bakery: bakery,
    teahouse: teahouse,
    steakhouse: steakhouse,
    hotel: hotel,
    motel: motel,
    lodge: lodge,
    resort: resort,
    hostel: hostel,
    barbershop: barbershop,
    building: building,
    stadium: stadium,
    gym: gym,
    market: market,
    office: office,
    burger:burger,
    bistro:bistro,
    breakfast:breakfast,
    chocolate:chocolate,
    coffee:coffee,
    eatry:eatry,
    pizzeria:pizzeria,
    plaza:plaza,
    seafood:seafood,
    smoothie:smoothie,
    supermarket:supermarket,
    sushi:sushi,
    tea:tea,
    vegan:vegan
  };


  const arrayOfIconImages = [
  "hotel",
  "premise [hotel]",
  "indian_restaurant",
  "fast_food_restaurant",
  "breakfast_restaurant",
  "restaurant",
  "food [restaurant]",
  "cafe",
  "coffee_shop",
  "bar [pub]",
  "pub",
  "night_club [pub]",
  "buffet [restaurant]",
  "barbecue_restaurant",
  "steak_house",
  "hostel",
  "barber_shop",
  "hair_care [barbershop]",
  "barbershop",
  "building",
  "stadium",
  "gym",
  "market",
  "office",
  "burger",
  "bistro",
  "breakfast",
  "chocolate",
  "coffee",
  "eatry",
  "pizzeria",
  "plaza",
  "seafood",
  "smoothie",
  "sushi",
  "tea",
  "vegan",
  ]


  const arrayOfImages2 = {
    restaurant: restaurant,
    premise:hotel,
    indian_restaurant:restaurant,
    fast_food_restaurant:restaurant,
    breakfast_restaurant:restaurant,
    steak_house:steakhouse,
    food:restaurant,
    cafe: cafe,
    coffee_shop:coffee,
    night_club:pub,
    hair_care:barbershop,
    pub: pub,
    bar: bar,
    barbecue_restaurant:restaurant,
    buffet: buffet,
    bakery: bakery,
    teahouse: teahouse,
    steakhouse: steakhouse,
    hotel: hotel,
    motel: motel,
    lodge: lodge,
    resort: resort,
    hostel: hostel,
    barbershop: barbershop,
    building: building,
    stadium: stadium,
    gym: gym,
    market: market,
    office: office,
    burger:burger,
    bistro:bistro,
    breakfast:breakfast,
    chocolate:chocolate,
    coffee:coffee,
    eatry:eatry,
    pizzeria:pizzeria,
    plaza:plaza,
    seafood:seafood,
    smoothie:smoothie,
    supermarket:supermarket,
    sushi:sushi,
    tea:tea,
    vegan:vegan,
    location:location,
    shopping_mall:plaza,
    place_of_worship:worship,
    museum:museum,
    tourist_attraction:tourist_attraction,
    historical_landmark:historical_landmark,
    park:park,
    amusement_park:amusement_park,
    movie_theater:movie_theater,
    hindu_temple:hindu_temple
  };



  const arrayOfImages2Array = [
    "restaurant",
    "premise",
    "indian_restaurant",
    "fast_food_restaurant",
    "breakfast_restaurant",
    "steak_house",
    "food",
    "cafe",
    "coffee_shop",
    "night_club",
    "hair_care",
    "pub",
    "bar",
    "barbecue_restaurant",
    "buffet",
    "bakery",
    "teahouse",
    "steakhouse",
    "hotel",
    "motel",
    "lodge",
    "resort",
    "hostel",
    "barbershop",
    "building",
    "stadium",
    "gym",
    "market",
    "office",
    "burger",
    "bistro",
    "breakfast",
    "chocolate",
    "coffee",
    "eatry",
    "pizzeria",
    "plaza",
    "seafood",
    "smoothie",
    "supermarket",
    "sushi",
    "tea",
    "vegan",
    "shopping_mall",
    "place_of_worship",
    "museum",
    "tourist_attraction",
    "historical_landmark",
    "park",
    "amusement_park",
    "movie_theater",
    "hindu_temple"
    
  ];




  const createMarkerElement = (placeType) => {
    // Assuming `nameToSendForMarkerType` is a variable containing the marker type
    // console.log("nameToSendForMarkerType",arrayOfImages[nameToSendForMarkerType]);
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.innerHTML = `<div class="map-marker-wrapper"><img src="${arrayOfImages2[placeType]}" alt="Marker Icon" height="30px" width="30px" /></div>`;
    return el;
  };

  const createDefaultMarkerElement = () => {
    // Assuming `nameToSendForMarkerType` is a variable containing the marker type
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.innerHTML = `<div class="map-marker-wrapper"><img src="${location}" alt="Default Marker Icon" height="30px" width="30px" /></div>`;
    return el;
  };

  const handlefunction = (value,current_idx) => {
    // console.log("current_idx-",current_idx)
    setCurrentlyClickedPlaceImg(value);
    setcurrentOptionChoose("openPopup");
    setCurrentIndex(current_idx)
  };

  useEffect(() => {
    let arr = [];
    if (dataToSendToMapboxForMarkers.length > 0) {
      dataToSendToMapboxForMarkers.forEach((place) => {
        arr.push([place.coordinates.lat, place.coordinates.lng]);
      });

    }
  }, [dataToSendToMapboxForMarkers]);


  // useEffect(() => {
  //   console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers)
  // }, [dataToSendToMapboxForMarkers])
  

  useEffect(() => {
    if (!map.current) {
      // console.log("uuuuuuuuuuuuuuuuuuuuuuu");
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
      });

      map.current.on("move", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      // Set marker options.
      if (
        dataToSendToMapboxForMarkers.length &&
        nameToSendForMarkerType !== ""
      ) {
        // if (nameToSendForMarkerType !== "notFound") {
        //   dataToSendToMapboxForMarkers.forEach((item,idx) => {
        //     const modifiedItem = item.placename.replace(/ /g, "");

        //     if (searchedPlaceDetailObject) {
        //       const img =
        //         searchedPlaceDetailObject[modifiedItem].place_photo_url;
        //       const imgSrc = `${img}`;

        //       const popupContent = document.createElement("div");
        //       popupContent.innerHTML = `
        //         <div class="map-marker-popup" style="color:#000">
        //         <h3>${item.placename}</h3>
        //         <img src="${imgSrc}" imgid="${modifiedItem}" alt="Image Alt Text" style="width: 100%; max-width: 300px;">
        //         <div>
        //       `;

        //       // Add a click event listener to the image
        //       const imgElement = popupContent.querySelector("img");
        //       imgElement.addEventListener("click", () => {
        //         handlefunction(modifiedItem,idx+1);
        //       });

        //       const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
        //         popupContent
        //       );
        //       const marker = new mapboxgl.Marker({
        //         element: createMarkerElement(),
        //         draggable: false,
        //       })
        //         .setLngLat([
        //           parseFloat(item.coordinates.lng),
        //           parseFloat(item.coordinates.lat),
        //         ])
        //         .setPopup(popup)
        //         .addTo(map.current);
        //     }
        //   });
        // } else {
        //   dataToSendToMapboxForMarkers.forEach((item,idx) => {
        //     const modifiedItem = item.placename.replace(/ /g, "");

        //     if (searchedPlaceDetailObject) {
        //       const img =
        //         searchedPlaceDetailObject[modifiedItem].place_photo_url;
        //       const imgSrc = `${img}`;

        //       const popupContent = document.createElement("div");
        //       popupContent.innerHTML = `
        //         <div class="map-marker-popup" style="color:#000">
        //         <h3>${item.placename}</h3>
        //         <img src="${imgSrc}" imgid="${modifiedItem}" alt="Image Alt Text" style="width: 100%; max-width: 300px;">
        //         <div>
        //       `;

        //       // Add a click event listener to the image
        //       const imgElement = popupContent.querySelector("img");
        //       imgElement.addEventListener("click", () => {
        //         handlefunction(modifiedItem,idx+1);
        //       });

        //       const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
        //         popupContent
        //       );

        //       const marker = new mapboxgl.Marker({
        //         element: createDefaultMarkerElement(),
        //         draggable: false,
        //       })
        //         .setLngLat([
        //           parseFloat(item.coordinates.lng),
        //           parseFloat(item.coordinates.lat),
        //         ])
        //         .setPopup(popup)
        //         .addTo(map.current);
        //     }
        //   });
        // }

          dataToSendToMapboxForMarkers.forEach((item,idx) => {
            const modifiedItem = item.placename.replace(/ /g, "");

            if (searchedPlaceDetailObject) {
              const img =
                searchedPlaceDetailObject[modifiedItem].place_photo_url;
              const imgSrc = `${img}`;

              const popupContent = document.createElement("div");
              popupContent.innerHTML = `
                <div class="map-marker-popup" style="color:#000">
                <h3>${item.placename}</h3>
                <img src="${imgSrc}" imgid="${modifiedItem}" alt="Image Alt Text" style="width: 100%; max-width: 300px;">
                <div>
              `;

              // Add a click event listener to the image
              const imgElement = popupContent.querySelector("img");
              imgElement.addEventListener("click", () => {
                handlefunction(modifiedItem,idx+1);
              });

              const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                popupContent
              );

              // console.log("item",item)

              if(item?.placeType!==null && arrayOfImages2Array.includes(item.placeType) ){
                // console.log(item.placename)
                const marker = new mapboxgl.Marker({
                  element: createMarkerElement(item.placeType),
                  draggable: false,
                })
                  .setLngLat([
                    parseFloat(item.coordinates.lng),
                    parseFloat(item.coordinates.lat),
                  ])
                  .setPopup(popup)
                  .addTo(map.current);
              }
              else{
                const marker = new mapboxgl.Marker({
                  element: createDefaultMarkerElement(),
                  draggable: false,
                })
                  .setLngLat([
                    parseFloat(item.coordinates.lng),
                    parseFloat(item.coordinates.lat),
                  ])
                  .setPopup(popup)
                  .addTo(map.current);
              }

            }
          });

      }

      // map.current.addControl(
      //   new mapboxgl.FullscreenControl({
      //     container: document.querySelector("body"),
      //   })
      // );

      map.current.addControl(new mapboxgl.NavigationControl());

      let arr = [];
      if (dataToSendToMapboxForMarkers.length > 0) {
        dataToSendToMapboxForMarkers.forEach((place) => {
          arr.push([place.coordinates.lng, place.coordinates.lat]);
        });

        // console.log(arr);
        // Helper function to get intermediate points for curve
        function getCurvePoints(p1, p2) {
          const curveTension = 0.3; // Adjust based on desired curve shape
          const controlPointX =
            (p1[0] + p2[0]) / 2 - (p1[1] - p2[1]) * curveTension;
          const controlPointY =
            (p1[1] + p2[1]) / 2 - (p2[0] - p1[0]) * curveTension;
          return [controlPointX, controlPointY];
        }

        // Create a new array for curved route
        let curveArr = [];

        for (let i = 0; i < arr.length - 1; i++) {
          const currentPoint = arr[i];
          const nextPoint = arr[i + 1];

          // Get intermediate control point
          const [controlX, controlY] = getCurvePoints(currentPoint, nextPoint);

          // Add current point and control point to curved route
          curveArr.push(currentPoint);
          curveArr.push([controlX, controlY]);
        }

        // Add the last point of the original array
        curveArr.push(arr[arr.length - 1]);


        ////////////////////////////////////////////////////////////////////////////////////////////
        map.current.on("load", () => {
          map.current.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: arr, // Use the curved array here
              },
            },
          });

          map.current.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#888",
              "line-width": 3,
            },
          });
        });


        ///////////////////////////////////////////////////////////////////////////////////////////////

        // fetch(
        //   `${BASEURL}/requesting-mapbox-route-paths`,
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${bearerToken}`,
        //     },
        //     body: JSON.stringify({
        //       arr: arr,
        //     }),
        //   })
        //   .then((response) => response.json())
        //   .then((data) => {
        //     console.log("T",data)
        //     const route = data.data.routes[0].geometry.coordinates;

        //     // Add route to map
        //     map.current.on("load", () => {
        //       map.current.addSource("route", {
        //         type: "geojson",
        //         data: {
        //           type: "Feature",
        //           properties: {},
        //           geometry: {
        //             type: "LineString",
        //             coordinates: route, // Use the route coordinates here
        //           },
        //         },
        //       });

        //       map.current.addLayer({
        //         id: "route",
        //         type: "line",
        //         source: "route",
        //         layout: {
        //           "line-join": "round",
        //           "line-cap": "round",
        //         },
        //         paint: {
        //           "line-color": "#888",
        //           "line-width": 3,
        //         },
        //       });
        //     });
        //   });
      }
    }
  }, [
    lng,
    lat,
    zoom,
    dataToSendToMapboxForMarkers,
    activePlaceOnMap,
    atFirst,
    placeNameZoomedInOnLoad,
    nameToSendForMarkerType,
    searchedPlaceDetailObject,
  ]);

  useEffect(() => {
    if (
      atFirst == 1 &&
      placeNameZoomedInOnLoad.length > 0 &&
      activePlaceOnMap == ""
    ) {
      // console.log("nnnnnnnnnnnnnnnnnnnnnnnn", placeNameZoomedInOnLoad);

      map.current.flyTo({
        bearing: 90,
        center: [
          placeNameZoomedInOnLoad[0].coordinates.lng,
          placeNameZoomedInOnLoad[0].coordinates.lat,
        ],
        zoom: 8,
        pitch: 40,
        duration: 9000,
      });
      setAtFirst(2);
    } else {
      // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
      map.current.flyTo(findCurrentData(activePlaceOnMap));
    }
  }, [placeNameZoomedInOnLoad, activePlaceOnMap]);

  useEffect(() => {
    let myarr = [];
    if (dataToSendToMapboxForMarkers.length > 0) {
      dataToSendToMapboxForMarkers.forEach((item) => {
        myarr.push({
          [item.placename.replace(/\s/g, "")]: {
            bearing: 90,
            center: [item.coordinates.lng, item.coordinates.lat],
            zoom: 17.3,
            pitch: 40,
            duration: 9000,
          },
        });
      });

      setDifferentLocationsMapCoordinates(myarr);
    }
  }, [dataToSendToMapboxForMarkers]);

  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  //   useEffect(() => {
  //     if (differentLocationsMapCoordinates.length > 0 && map.current) {
  //       map.current.flyTo(differentLocationsMapCoordinates[activePlaceOnMap]);
  //       console.log("Flying to a new location");
  //     }
  //   }, [activePlaceOnMap, differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log(
  //     "findCurrentData(activePlaceOnMap)",
  //     findCurrentData(activePlaceOnMap)
  //   );
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log(
  //     "differentLocationsMapCoordinates",
  //     differentLocationsMapCoordinates
  //   );
  // }, [differentLocationsMapCoordinates]);

  // useEffect(() => {
  //   console.log("activePlaceOnMap", activePlaceOnMap);
  // }, [activePlaceOnMap]);

  // useEffect(() => {
  //   console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers)
  // }, [dataToSendToMapboxForMarkers])

  // useEffect(() => {
  //   console.log("searchedPlaceDetailObject",searchedPlaceDetailObject)
  // }, [searchedPlaceDetailObject])

  return (
    <div>
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default MapboxMap;
