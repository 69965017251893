/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { divIcon } from "leaflet";
import axios from "axios";
import "./PropertyListingMap.css";
import { BASEURL } from "../../api";
import "leaflet/dist/leaflet.css";

import MarkerClusterGroup from "react-leaflet-cluster";

const authorInfo = {
  firstName: "",
  lastName: "",
  role: "",
  company: "",
};

const PropertyListingMap = ({ data }) => {
  const [allCoordinates, setAllCoordinates] = useState([]);
  const [mapCenterLatitude, setMapCenterLatitude] = useState(null);
  const [mapCenterLongitude, setMapCenterLongitude] = useState(null);

  useEffect(() => {
    if (data.length > 0) {
      data.map((item) => {
        return item.properties.map((latlng) => {
          return setAllCoordinates((prev) => [...prev, latlng.coordinates]);
        });
      });
    }
  }, [data]);

  useEffect(() => {

    if (allCoordinates.length > 0) {
      setMapCenterLatitude(allCoordinates[0][0]);
      setMapCenterLongitude(allCoordinates[0][1]);
    }
  }, [allCoordinates]);



  return (
    <div>
      {data.length > 0 && (
        <div>
          <MapContainer
            center={[42.599326, 1.539671]}
            zoom={8}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              minZoom={0}
              maxZoom={20}
              ext="png"
            />

            <MarkerClusterGroup
              spiderfyDistanceMultiplier={7}
              spiderfyOnMaxZoom={true}
              // disableClusteringAtZoom={18}
            >
              {data.length > 0 &&
                data.map((items, idx) =>
                  items.properties.map((property) => (
                    <>
                      <Marker
                        key={idx + property.property_id}
                        icon={createCustomIcon(
                          property.property_price,
                          property.capacity,
                          property.property_images
                        )}
                        position={[
                          parseFloat(property.coordinates[0]),
                          parseFloat(property.coordinates[1]),
                        ]}
                      >
                        <Popup key={idx + property.property_id}>
                          <div className="custom-popup">
                            <div className="card">
                              <img
                                src={`${BASEURL}${property.property_images}`}
                              />
                              <div className="card-content">
                                <p className="card-heading1">
                                  <b>Price </b>- Desde {property.property_price}€ /
                                  persona
                                </p>
                                <p className="card-heading2">
                                  <b>People </b>- {property.capacity} personas
                                </p>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    </>
                  ))
                )}
            </MarkerClusterGroup>
          </MapContainer>
        </div>
      )}
    </div>
  );
};

// Function to create a custom marker icon
// function createCustomIcon(
//   price,
//   capacity,
//   image
// ) {
//   let iconHtml = `
//     <div class="map-card-wrapper">
//       <img src="http://localhost:5000${image}" />
//       <div class="map-card-txt">
//         <h2 class='popup-heading'>
//           Price - ${price}
//         </h2>
//             <div class="shared-mingle-handshake">
//             <p class="popup-heading-para">Capacity - ${capacity}</p>
//               </div>

//       </div>
//     </div>
//   `;

//   // eslint-disable-next-line no-undef
//   const customIcon = L.divIcon({
//     className: "custom-marker",
//     html: iconHtml,
//   });

//   return customIcon;
// }

function createCustomIcon(price, capacity, image) {
  let iconHtml = `
  
      <svg class="home-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="594.657px" height="832.35px" viewBox="0 0 594.657 832.35" enable-background="new 0 0 594.657 832.35" xml:space="preserve">
      <path fill="#FFCD0A" d="M507.572,87.086C451.414,30.928,376.748,0,297.329,0S143.244,30.928,87.086,87.086S0,217.91,0,297.33
          c0,37.328,8.104,75.127,24.773,115.561c13.006,31.545,31.131,64.504,57.041,103.725l82.887,125.467l113.352,180.572
          c4.189,6.676,11.396,10.66,19.276,10.66c7.881,0,15.087-3.984,19.276-10.66l113.319-180.521l82.919-125.518
          c25.911-39.221,44.035-72.18,57.041-103.725c16.67-40.434,24.772-78.232,24.772-115.561
          C594.657,217.91,563.729,143.244,507.572,87.086z" class="pin-color"></path>
      <path fill="#010101" d="M457.477,262.824L303.686,139.79c-4.277-2.983-8.552-2.983-12.817,0l-55.108,44.215v-25.632
          c0-5.547-2.033-10.352-6.086-14.418c-4.066-4.056-8.872-6.088-14.419-6.088c-5.557,0-10.362,2.032-14.418,6.088
          c-4.065,4.066-6.088,8.871-6.088,14.418v58.313l-57.672,46.139c-5.126,4.274-5.657,9.08-1.602,14.418
          c4.056,5.345,8.86,6.086,14.418,2.241l24.349-19.863v144.818c0,5.547,2.024,10.354,6.089,14.418
          c4.055,4.056,8.86,6.089,14.418,6.089h61.517c5.547,0,10.353-2.033,14.418-6.089c4.056-4.063,6.087-8.871,6.087-14.418v-82.021
          h41.013v82.021c0,5.547,2.022,10.354,6.086,14.418c4.056,4.056,8.86,6.089,14.418,6.089h61.517c5.547,0,10.353-2.033,14.418-6.089
          c4.056-4.063,6.089-8.871,6.089-14.418V259.62l24.349,19.863c1.702,1.281,3.845,1.924,6.408,1.924c3.415,0,6.188-1.283,8.33-3.845
          C463.242,272.016,462.603,267.099,457.477,262.824z" class="house-color"></path>
  </svg>

    `;

  // eslint-disable-next-line no-undef
  const customIcon = L.divIcon({
    className: "custom-marker",
    html: iconHtml,
  });

  return customIcon;
}

// function createCustomIcon1(
//   postTitle,
//   endsAt,
//   startsAt,
//   post_image,
//   customClass,
//   postType,
//   post_permalink
// ) {
//   let imageHtml = "";
//   if (post_image) {
//     imageHtml = `<img class="img-fluid map-user-image-for-community" src="${post_image}" />`;
//   } else {
//     // Use a placeholder image if post_image is not defined
//     imageHtml =
//       `<img class="img-fluid map-user-image-for-community" src="https://placehold.co/70?text=` +
//       postTitle +
//       `&font=roboto" />`;
//   }

//   let iconHtml =
//     `
//     <a href=` +
//     post_permalink +
//     `>
//     <div class="map-card-wrapper ${customClass}">
//       ${imageHtml}
//       <div class="map-card-txt">
//         <a href=` +
//     post_permalink +
//     `>
//         <h2 class='popup-heading'>${
//           postTitle &&
//           postTitle.slice(0, 12) + (postTitle.length > 12 ? "..." : "")
//         }</h2>
//         </a>
//         ${
//           endsAt
//             ? `<p class="popup-heading-para">${formatEndTime(
//                 startsAt,
//                 endsAt
//               )}</p>`
//             : ""
//         }
//       </div>
//     </div>
//     </a>
//   `;
//   if (postType === "event" || postType === "community") {
//     iconHtml = `<a href="${post_permalink}">${iconHtml}</a>`;
//   }
//   const customIcon = L.divIcon({
//     className: "custom-marker",
//     html: iconHtml,
//   });

//   return customIcon;
// }

// function createCustomIcon2(
//   postTitle,
//   post_image,
//   customClass,
//   postType,
//   post_permalink
// ) {
//   let iconHtml =
//     `
//     <a href=` +
//     post_permalink +
//     `>
//     <div class="map-card-wrapper-community  ${customClass}">
//       <div class="map-card-txt">
//       <h2 class='popup-heading'>${
//         postTitle &&
//         postTitle
//           .split(" ")
//           .map((word) => word[0].toUpperCase())
//           .join("")
//       }</h2>

//       </div>
//     </div>
//     </a>
//   `;
//   if (postType === "event" || postType === "community") {
//     iconHtml = `<a href="${post_permalink}">${iconHtml}</a>`;
//   }
//   const customIcon = L.divIcon({
//     className: "custom-marker",
//     html: iconHtml,
//   });

//   return customIcon;
// }

// function createCustomIconForCurrentUser(customClass) {
//   let iconHtml = `

//   <div class="map-card-wrapper-community  ${customClass}" style="width:20px;height:20px">
//     <div class="center">
//       <div class="circle pulse blue"></div>
//     </div>
//   </div>

//   `;

//   const customIcon = L.divIcon({
//     className: "custom-marker",
//     html: iconHtml,
//   });

//   return customIcon;
// }

export default PropertyListingMap;
