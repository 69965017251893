import React from 'react';
import PropertyListing from './PropertyListing';
import { useNavigate } from 'react-router-dom';

const ProtectedPropertyListing = ({isLoggedIn, setIsLoggedIn}) => {
    const localStoragetoken = localStorage.getItem("token");
    const navigate = useNavigate();
  
    if (!localStoragetoken) {
      navigate('/login');
      window.location.href='/';
      return null; // Return null or any other component if you prefer
    }
  
    // Render the WorldMap component if the "token" exists
    return <PropertyListing isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />;

}

export default ProtectedPropertyListing