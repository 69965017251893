import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDt1KHTMyENhnz3cjWZZ852Cs9kh0Vpdsg",
    authDomain: "triptick-3bcfd.firebaseapp.com",
    projectId: "triptick-3bcfd",
    storageBucket: "triptick-3bcfd.appspot.com",
    messagingSenderId: "833133258968",
    appId: "1:833133258968:web:9670d6cd23e7910a6e0fad",
    measurementId: "G-ZJC4T0NP3D"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)