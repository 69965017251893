import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Card from "./Card";
import { useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = ({ properties }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {properties.length > 2 ? (
        <Slider {...settings}>
          {properties &&
            properties.map((property) => (
              <div key={property.property_id}>
                <Card
                  property_price={property.property_price}
                  capacity={property.capacity}
                  property_images={property.property_images}
                  property_link={property.property_link}
                />
              </div>
            ))}
        </Slider>
      ) : (
        <>
          <div className="non-slider-cards">
            {properties && properties.length === 1 ? (
              <>
              <div className="card-one">
              {properties &&
                  properties.map((property) => (
                    <>
                    
                      <Card
                        property_price={property.property_price}
                        capacity={property.capacity}
                        property_images={property.property_images}
                        property_link={property.property_link}
                      />
                    </>
                  ))}
              </div>

              </>
            ) : (
              <>
              <div className="card-two">
              {properties &&
                  properties.map((property) => (
                    <>
                    
                      <Card
                        property_price={property.property_price}
                        capacity={property.capacity}
                        property_images={property.property_images}
                        property_link={property.property_link}
                      />
                    </>
                  ))}
              </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CardSlider;
