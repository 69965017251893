import { Route, Routes } from "react-router-dom";
// import "./App.css";
import NearByPlaces from "./components/nearbyplaces/nearByPlaces";
import HomePage from "./components/homepage/homepage";
import Login from "./components/pages/Login";
import SignUp from "./components/pages/SignUp";
import ForgetPassword from "./components/pages/ForgetPassword";
import ResetPassword from "./components/pages/ResetPassword";

import ProtectedWorldMapRoute from "./components/worldmap/ProtectedWorldMapRoute"; // Import the new component
import MyTrips from "./components/pages/MyTrips";
import ProtectedMyTrips from "./components/pages/ProtectedMyTrips";
import ProtectedPropertyListing from "./components/property_listing/ProtectedPropertyListing";
import { useRef, useState, useEffect } from "react";
import Cookies from "js-cookie";
import TriptickPrivacyPolicy from "./components/PrivacyPolicy/TriptickPrivacyPolicy";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataToSendToMapboxForMarkers, setDataToSendToMapboxForMarkers] =
    useState([]);
  const [searchedPlaceName, setSearchedPlaceName] = useState("");

  useEffect(() => {
    // console.log("isLoggedIn",isLoggedIn)
    if (localStorage.getItem("token") || localStorage.getItem("googletoken")) {
      setIsLoggedIn(
        localStorage.getItem("token") || localStorage.getItem("googletoken")
      );
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   // console.log("isLoggedIn",isLoggedIn)
  //   if (isLoggedIn) {
  //     try {
  //         // Create a new script element
  //         var scriptElement = document.createElement('script');
  //         // Set the attributes for the script element
  //         scriptElement.setAttribute('data-id', 'J9U3JLwOdkgNRxQnCklZ');
  //         scriptElement.setAttribute('src', 'https://chat-component-svelte2.netlify.app/build/bundle.js');
  //         // Append the script element to the body or any other desired location
  //         document.body.appendChild(scriptElement);
  //     } catch (error) {
  //         console.error('Script loading error:', error);
  //     }
  // }

  // }, [isLoggedIn])

  useEffect(() => {
    // Add a click event listener to the document body
    document.body.addEventListener("click", function (event) {
      // Get the .header-dd-input element
      const headerDDInput = document.querySelector(".header-dd-input");

      // Get the .header-dd-menu element
      const headerDDMenu = document.querySelector(".header-dd-menu");

      // Check if the .header-dd-input is checked
      const isChecked = headerDDInput.checked;

      // Check if the .header-dd-menu is visible
      const isMenuVisible =
        window.getComputedStyle(headerDDMenu).display === "block";
      const headerDropdown = document.querySelector(".header-dropdown");
      // Check if the clicked element is outside the .header-dropdown
      if (!headerDropdown.contains(event.target)) {
        if (isChecked && isMenuVisible) {
          // Get the checkbox element
          const testCheckbox = document.getElementById("test");

          // Uncheck the checkbox
          if (testCheckbox) {
            testCheckbox.checked = false;
          }
        }
      }
    });
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <HomePage
            searchedPlaceName={searchedPlaceName}
            setSearchedPlaceName={setSearchedPlaceName}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            dataToSendToMapboxForMarkers={dataToSendToMapboxForMarkers}
            setDataToSendToMapboxForMarkers={setDataToSendToMapboxForMarkers}
          />
        }
      />
      <Route
        exact
        path="/nearbyplaces/:Id"
        element={
          <NearByPlaces
            dataToSendToMapboxForMarkers={dataToSendToMapboxForMarkers}
            setDataToSendToMapboxForMarkers={setDataToSendToMapboxForMarkers}
          />
        }
      />
      <Route
        exact
        path="/login"
        element={
          <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        }
      />
      <Route exact path="/privacy-policy" element={<TriptickPrivacyPolicy />} />
      <Route
        exact
        path="/signup"
        element={
          <SignUp isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        }
      />
      <Route exact path="/forgetpassword" element={<ForgetPassword />} />
      <Route exact path="/resetpassword" element={<ResetPassword />} />
      <Route
        exact
        path="/my-trips"
        element={
          <ProtectedMyTrips
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
          />
        }
      />
      <Route
        exact
        path="/property-listing"
        element={
          <ProtectedPropertyListing
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
          />
        }
      />
      {/* Use the new ProtectedWorldMapRoute component for /world-map */}
      <Route
        exact
        path="/world-map"
        element={
          <ProtectedWorldMapRoute
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
          />
        }
      />
    </Routes>
  );
}

export default App;
