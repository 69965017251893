import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDt1KHTMyENhnz3cjWZZ852Cs9kh0Vpdsg",
  authDomain: "triptick-3bcfd.firebaseapp.com",
  projectId: "triptick-3bcfd",
  storageBucket: "triptick-3bcfd.appspot.com",
  messagingSenderId: "833133258968",
  appId: "1:833133258968:web:9670d6cd23e7910a6e0fad",
  measurementId: "G-ZJC4T0NP3D"
};

if (!firebase.app.lenth) {
  firebase.initializeApp(firebaseConfig);
}

const app = initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export const db = getFirestore(app);
