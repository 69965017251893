import React from "react";
import "./placePopup.css";
import demoimg from "../../assets/images/Amigos Outdoors.png";
import crossIcon from "../../assets/images/close.png";
import { BASEURL } from "../../api";
import { useState, useEffect } from "react";

const PlacePopup = ({
  currentlyClickedPlaceImg,
  setCurrentlyClickedPlaceImg,
  currentClickedPlaceDetailObject,
  setCurrentClickedPlaceDetailObject,
  currentOptionChoose,
  setcurrentOptionChoose,
  currentIndex,
  setCurrentIndex,
  lengthOfObject,
  searchedPlaceDetailObject,
  entriesArray,
  entriesArray1,
  screenWidth,
}) => {
  const [isRendered, setIsRendered] = useState(false);
  const handleCloseFunction = () => {
    setCurrentlyClickedPlaceImg("");
    setcurrentOptionChoose("closePopup");
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    if (currentlyClickedPlaceImg != "") {
      setTimeout(() => {
        setIsRendered(true);
      }, 500);
    } else {
      setTimeout(() => {
        setIsRendered(false);
      }, 500);
    }
  }, [currentlyClickedPlaceImg]);

  //   useEffect(() => {
  //     console.log("llll",searchedPlaceDetailObject);
  //     if(Object.keys(searchedPlaceDetailObject).length > 0){
  //       setEntriesArray(Object.entries(searchedPlaceDetailObject));
  // console.log(entriesArray);
  //     }
  //   }, [searchedPlaceDetailObject])

  // useEffect(() => {
  //   console.log(entriesArray);
  // }, [entriesArray]);

  function removeSpecialSymbols(inputString) {
    // Use a regular expression to remove special symbols, including emojis and specific box-like characters
    const cleanedString = inputString.replace(/[^\w\s🏨\u20E3^$%&*+?.()|\[\]{}<>'"`-]/gi, '');
  
    return cleanedString;
  }



  function removeSpecialCharacter(inputString) {
    // Replace the ⃣ character with an empty string
    return inputString.replace(/⃣/g, '');
  }

  return (
    <>
      {screenWidth < 992 ? (
        <>
          <div
            id="backdrop"
            className="backdrop"
            style={{
              opacity:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? "1"
                  : "0",
              transition:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? ".5s all ease-in-out"
                  : ".5s all ease-in-out",
              zIndex:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? "9"
                  : "-1",
            }}
          ></div>
          <div
            id="myPopup"
            className="popup hide result-popup"
            style={{
              visibility:
                currentlyClickedPlaceImg != "" && isRendered
                  ? "visible"
                  : "hidden",
              top:
                currentlyClickedPlaceImg != "" && isRendered ? "calc(100vh - 95%)" : "100%",
              opacity:
                currentlyClickedPlaceImg != "" && isRendered != "" ? "1" : "0",
            }}
          >
            <div className="popup-header">
              <span
                id="closeBtn"
                className="close toggle"
                data-target="myPopup"
                onClick={() => setCurrentlyClickedPlaceImg("")}
              >
                close
              </span>
            </div>
            <div className="popup-body">
              <div className="popup-inner-content">
                <div className="popup-heading-wrapper">
                  {currentIndex !== null ? (
                    <span className="circle-count">{currentIndex}</span>
                  ) : (
                    <></>
                  )}

                  <div className="popup-title">
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail ? (
                      <h4>
                        {
                          currentClickedPlaceDetailObject.place_detail
                            .displayName.text
                        }
                      </h4>
                    ) : (
                      <></>
                    )}
                    {/* <h4>The Wolseley</h4> */}

                    {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "formattedAddress"
                      ) &&
                      currentClickedPlaceDetailObject.place_detail
                        .formattedAddress}
                    {/* <p>160 Piccadilly, St. James's, London W1J 9EB, UK</p> */}
                  </div>
                </div>
                <div className="popup-inner-content-wrapper">
                  <div className="popup-image-warpper">
                    {currentClickedPlaceDetailObject ? (
                      <img
                        src={currentClickedPlaceDetailObject.place_photo_url}
                        className="img-fluid"
                        alt=""
                      />
                    ) : (
                      <></>
                    )}

                    {/* <img src="/triptick-new/triptick-new/images/img (1).png" className="img-fluid" alt="" />
          <img src="/triptick-new/triptick-new/images/img (2).png" className="img-fluid" alt="" /> */}
                  </div>
                  <div className="popup-discription-wrapper">
                    <div className="dis-head">
                      <h5>Description:</h5>
                      {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_description ? (
                        <p className="dis-para">
                          {removeSpecialCharacter(currentClickedPlaceDetailObject.place_description)}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="dis-head">
                      <h5>International phone number:</h5>
                      {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "internationalPhoneNumber"
                      ) ? (
                        <>
                          <p className="dis-para">
                            {
                              currentClickedPlaceDetailObject.place_detail
                                .internationalPhoneNumber
                            }
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="dis-para">NA</p>
                        </>
                      )}
                    </div>

                    <div className="dis-head">
                      <h5>Open now:</h5>
                      {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "currentOpeningHours"
                      ) ? (
                        <>
                          <p className="dis-para">
                            {`${
                              currentClickedPlaceDetailObject.place_detail
                                .currentOpeningHours.openNow === false
                                ? "No"
                                : "Yes"
                            }`}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="dis-para">NA</p>
                        </>
                      )}
                    </div>
                    <div className="dis-head">
                      <h5>Workday descriptions:</h5>
                      {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "currentOpeningHours"
                      ) ? (
                        <>
                          {currentClickedPlaceDetailObject.place_detail
                            .currentOpeningHours.weekdayDescriptions.length >
                            0 &&
                            currentClickedPlaceDetailObject.place_detail.currentOpeningHours.weekdayDescriptions.map(
                              (item, index) => (
                                <p className="dis-para" key={index}>
                                  {item}
                                </p>
                              )
                            )}
                        </>
                      ) : (
                        <>
                          <p className="dis-para">NA</p>
                        </>
                      )}
                    </div>
                    <div className="dis-head">
                      <h5>Accessibility options:</h5>

                      {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "accessibilityOptions"
                      ) ? (
                        <>
                          <p className="dis-para">
                            Wheelchair accessible entrance -{" "}
                            {`${
                              currentClickedPlaceDetailObject.place_detail
                                .wheelchairAccessibleEntrance == false
                                ? "No"
                                : "Yes"
                            }`}{" "}
                          </p>
                          <p className="dis-para">
                            Wheelchair accessible parking -{" "}
                            {`${
                              currentClickedPlaceDetailObject.place_detail
                                .wheelchairAccessibleParking == false
                                ? "No"
                                : "Yes"
                            }`}{" "}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="dis-para">NA</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <button
                className="prev-btn"
                data-target="myPopup"
                onClick={() => {
                  setCurrentlyClickedPlaceImg(
                    entriesArray[currentIndex - 2][0]
                  );
                  setCurrentIndex(currentIndex - 1);
                }}
              >
                {currentIndex ? (
                  <>
                    {currentIndex == 1 ? (
                      <></>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                        >
                          <path
                            d="M19.1996 8C19.1996 8.21216 19.1153 8.41563 18.9653 8.56565C18.8152 8.71567 18.6118 8.79995 18.3996 8.79995H2.73148L8.56615 14.6336C8.64048 14.708 8.69943 14.7962 8.73966 14.8933C8.77988 14.9904 8.80058 15.0945 8.80058 15.1996C8.80058 15.3047 8.77988 15.4088 8.73966 15.5059C8.69943 15.603 8.64048 15.6912 8.56615 15.7656C8.49183 15.8399 8.40359 15.8988 8.30648 15.9391C8.20938 15.9793 8.10529 16 8.00018 16C7.89507 16 7.79099 15.9793 7.69388 15.9391C7.59678 15.8988 7.50854 15.8399 7.43422 15.7656L0.234616 8.56597C0.160239 8.49167 0.101235 8.40345 0.0609782 8.30633C0.020721 8.20922 0 8.10513 0 8C0 7.89487 0.020721 7.79078 0.0609782 7.69366C0.101235 7.59655 0.160239 7.50832 0.234616 7.43403L7.43422 0.234432C7.58432 0.0843276 7.7879 -1.5816e-09 8.00018 0C8.21246 1.5816e-09 8.41605 0.0843276 8.56615 0.234432C8.71626 0.384536 8.80058 0.588121 8.80058 0.8004C8.80058 1.01268 8.71626 1.21626 8.56615 1.36637L2.73148 7.20004H18.3996C18.6118 7.20004 18.8152 7.28432 18.9653 7.43435C19.1153 7.58437 19.1996 7.78784 19.1996 8Z"
                            fill="#605B52"
                          />
                        </svg>{" "}
                        <span className="circle">{currentIndex - 1}</span>
                        {entriesArray.length > 0 ? (
                          entriesArray[currentIndex - 2][1].place_detail
                            .displayName.text
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </button>
              <button
                className="prev-btn next-btn"
                data-target="myPopup"
                onClick={() => {
                  setCurrentlyClickedPlaceImg(entriesArray[currentIndex][0]);
                  setCurrentIndex(currentIndex + 1);
                }}
              >
                {currentIndex ? (
                  <>
                    {currentIndex == lengthOfObject ? (
                      <></>
                    ) : (
                      <>
                        {entriesArray.length > 0 ? (
                          entriesArray[currentIndex][1].place_detail.displayName
                            .text
                        ) : (
                          <></>
                        )}
                        <span className="circle">{currentIndex + 1}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                        >
                          <path
                            d="M0.000146866 8C0.000146866 8.21216 0.0844269 8.41563 0.234447 8.56565C0.384468 8.71567 0.58794 8.79995 0.800102 8.79995H16.4682L10.6336 14.6336C10.5592 14.708 10.5003 14.7962 10.46 14.8933C10.4198 14.9904 10.3991 15.0945 10.3991 15.1996C10.3991 15.3047 10.4198 15.4088 10.46 15.5059C10.5003 15.603 10.5592 15.6912 10.6336 15.7656C10.7079 15.8399 10.7961 15.8988 10.8932 15.9391C10.9903 15.9793 11.0944 16 11.1995 16C11.3046 16 11.4087 15.9793 11.5058 15.9391C11.6029 15.8988 11.6912 15.8399 11.7655 15.7656L18.9651 8.56597C19.0395 8.49167 19.0985 8.40345 19.1387 8.30633C19.179 8.20922 19.1997 8.10513 19.1997 8C19.1997 7.89487 19.179 7.79078 19.1387 7.69366C19.0985 7.59655 19.0395 7.50832 18.9651 7.43403L11.7655 0.234432C11.6154 0.0843276 11.4118 -1.5816e-09 11.1995 0C10.9872 1.5816e-09 10.7837 0.0843276 10.6336 0.234432C10.4834 0.384536 10.3991 0.588121 10.3991 0.8004C10.3991 1.01268 10.4834 1.21626 10.6336 1.36637L16.4682 7.20004H0.800102C0.58794 7.20004 0.384468 7.28432 0.234447 7.43435C0.0844269 7.58437 0.000146866 7.78784 0.000146866 8Z"
                            fill="#605B52"
                          />
                        </svg>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="backdrop"
            className="backdrop"
            style={{
              opacity:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? "1"
                  : "0",
              transition:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? ".5s all ease-in-out"
                  : ".5s all ease-in-out",
              zIndex:
                currentlyClickedPlaceImg !== "" && isRendered == true
                  ? "9"
                  : "-1",
            }}
          ></div>
          <div
            id="myPopup"
            className="popup hide result-popup"
            style={{
              visibility:
                currentlyClickedPlaceImg != "" && isRendered
                  ? "visible"
                  : "hidden",
              left:
                currentlyClickedPlaceImg != "" && isRendered ? "50%" : "100%",
              opacity:
                currentlyClickedPlaceImg != "" && isRendered != "" ? "1" : "0",
            }}
          >
            <div className="popup-header">
              <span
                id="closeBtn"
                className="close toggle"
                data-target="myPopup"
                onClick={() => setCurrentlyClickedPlaceImg("")}
              >
                close
              </span>
            </div>
            <div className="popup-body">
              <div className="popup-inner-content">
                <div className="popup-heading-wrapper">
                  {currentIndex !== null ? (
                    <span className="circle-count">{currentIndex}</span>
                  ) : (
                    <></>
                  )}

                  <div className="popup-title">
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail ? (
                      <h4>
                        {
                          currentClickedPlaceDetailObject.place_detail
                            .displayName.text
                        }
                      </h4>
                    ) : (
                      <></>
                    )}
                    {/* <h4>The Wolseley</h4> */}

                    {currentClickedPlaceDetailObject &&
                      currentClickedPlaceDetailObject.place_detail &&
                      currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                        "formattedAddress"
                      ) &&
                      currentClickedPlaceDetailObject.place_detail
                        .formattedAddress}
                    {/* <p>160 Piccadilly, St. James's, London W1J 9EB, UK</p> */}
                  </div>
                </div>

                <div className="popup-image-warpper">
                  {currentClickedPlaceDetailObject ? (
                    <img
                      src={currentClickedPlaceDetailObject.place_photo_url}
                      className="img-fluid"
                      alt=""
                    />
                  ) : (
                    <></>
                  )}

                  {/* <img src="/triptick-new/triptick-new/images/img (1).png" className="img-fluid" alt="" />
          <img src="/triptick-new/triptick-new/images/img (2).png" className="img-fluid" alt="" /> */}
                </div>
                <div className="popup-discription-wrapper">
                  <div className="dis-head">
                    <h5>Description:</h5>
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_description ? (
                      <p className="dis-para">
                        {removeSpecialCharacter(currentClickedPlaceDetailObject.place_description)}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dis-head">
                    <h5>International phone number:</h5>
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail &&
                    currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                      "internationalPhoneNumber"
                    ) ? (
                      <>
                        <p className="dis-para">
                          {
                            currentClickedPlaceDetailObject.place_detail
                              .internationalPhoneNumber
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="dis-para">NA</p>
                      </>
                    )}
                  </div>

                  <div className="dis-head">
                    <h5>Open now:</h5>
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail &&
                    currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                      "currentOpeningHours"
                    ) ? (
                      <>
                        <p className="dis-para">
                          {`${
                            currentClickedPlaceDetailObject.place_detail
                              .currentOpeningHours.openNow === false
                              ? "No"
                              : "Yes"
                          }`}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="dis-para">NA</p>
                      </>
                    )}
                  </div>
                  <div className="dis-head">
                    <h5>Workday descriptions:</h5>
                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail &&
                    currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                      "currentOpeningHours"
                    ) ? (
                      <>
                        {currentClickedPlaceDetailObject.place_detail
                          .currentOpeningHours.weekdayDescriptions.length > 0 &&
                          currentClickedPlaceDetailObject.place_detail.currentOpeningHours.weekdayDescriptions.map(
                            (item, index) => (
                              <p className="dis-para" key={index}>
                                {item}
                              </p>
                            )
                          )}
                      </>
                    ) : (
                      <>
                        <p className="dis-para">NA</p>
                      </>
                    )}
                  </div>
                  <div className="dis-head">
                    <h5>Accessibility options:</h5>

                    {currentClickedPlaceDetailObject &&
                    currentClickedPlaceDetailObject.place_detail &&
                    currentClickedPlaceDetailObject.place_detail.hasOwnProperty(
                      "accessibilityOptions"
                    ) ? (
                      <>
                        <p className="dis-para">
                          Wheelchair accessible entrance -{" "}
                          {`${
                            currentClickedPlaceDetailObject.place_detail
                              .wheelchairAccessibleEntrance == false
                              ? "No"
                              : "Yes"
                          }`}{" "}
                        </p>
                        <p className="dis-para">
                          Wheelchair accessible parking -{" "}
                          {`${
                            currentClickedPlaceDetailObject.place_detail
                              .wheelchairAccessibleParking == false
                              ? "No"
                              : "Yes"
                          }`}{" "}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="dis-para">NA</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <button
                className="prev-btn"
                data-target="myPopup"
                onClick={() => {
                  setCurrentlyClickedPlaceImg(
                    entriesArray[currentIndex - 2][0]
                  );
                  setCurrentIndex(currentIndex - 1);
                }}
              >
                {currentIndex ? (
                  <>
                    {currentIndex == 1 ? (
                      <></>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                        >
                          <path
                            d="M19.1996 8C19.1996 8.21216 19.1153 8.41563 18.9653 8.56565C18.8152 8.71567 18.6118 8.79995 18.3996 8.79995H2.73148L8.56615 14.6336C8.64048 14.708 8.69943 14.7962 8.73966 14.8933C8.77988 14.9904 8.80058 15.0945 8.80058 15.1996C8.80058 15.3047 8.77988 15.4088 8.73966 15.5059C8.69943 15.603 8.64048 15.6912 8.56615 15.7656C8.49183 15.8399 8.40359 15.8988 8.30648 15.9391C8.20938 15.9793 8.10529 16 8.00018 16C7.89507 16 7.79099 15.9793 7.69388 15.9391C7.59678 15.8988 7.50854 15.8399 7.43422 15.7656L0.234616 8.56597C0.160239 8.49167 0.101235 8.40345 0.0609782 8.30633C0.020721 8.20922 0 8.10513 0 8C0 7.89487 0.020721 7.79078 0.0609782 7.69366C0.101235 7.59655 0.160239 7.50832 0.234616 7.43403L7.43422 0.234432C7.58432 0.0843276 7.7879 -1.5816e-09 8.00018 0C8.21246 1.5816e-09 8.41605 0.0843276 8.56615 0.234432C8.71626 0.384536 8.80058 0.588121 8.80058 0.8004C8.80058 1.01268 8.71626 1.21626 8.56615 1.36637L2.73148 7.20004H18.3996C18.6118 7.20004 18.8152 7.28432 18.9653 7.43435C19.1153 7.58437 19.1996 7.78784 19.1996 8Z"
                            fill="#605B52"
                          />
                        </svg>{" "}
                        <span className="circle">{currentIndex - 1}</span>
                        {entriesArray.length > 0 ? (
                          entriesArray[currentIndex - 2][1].place_detail
                            .displayName.text
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </button>
              <button
                className="prev-btn next-btn"
                data-target="myPopup"
                onClick={() => {
                  setCurrentlyClickedPlaceImg(entriesArray[currentIndex][0]);
                  setCurrentIndex(currentIndex + 1);
                }}
              >
                {currentIndex ? (
                  <>
                    {currentIndex == lengthOfObject ? (
                      <></>
                    ) : (
                      <>
                        {entriesArray.length > 0 ? (
                          entriesArray[currentIndex][1].place_detail.displayName
                            .text
                        ) : (
                          <></>
                        )}
                        <span className="circle">{currentIndex + 1}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                        >
                          <path
                            d="M0.000146866 8C0.000146866 8.21216 0.0844269 8.41563 0.234447 8.56565C0.384468 8.71567 0.58794 8.79995 0.800102 8.79995H16.4682L10.6336 14.6336C10.5592 14.708 10.5003 14.7962 10.46 14.8933C10.4198 14.9904 10.3991 15.0945 10.3991 15.1996C10.3991 15.3047 10.4198 15.4088 10.46 15.5059C10.5003 15.603 10.5592 15.6912 10.6336 15.7656C10.7079 15.8399 10.7961 15.8988 10.8932 15.9391C10.9903 15.9793 11.0944 16 11.1995 16C11.3046 16 11.4087 15.9793 11.5058 15.9391C11.6029 15.8988 11.6912 15.8399 11.7655 15.7656L18.9651 8.56597C19.0395 8.49167 19.0985 8.40345 19.1387 8.30633C19.179 8.20922 19.1997 8.10513 19.1997 8C19.1997 7.89487 19.179 7.79078 19.1387 7.69366C19.0985 7.59655 19.0395 7.50832 18.9651 7.43403L11.7655 0.234432C11.6154 0.0843276 11.4118 -1.5816e-09 11.1995 0C10.9872 1.5816e-09 10.7837 0.0843276 10.6336 0.234432C10.4834 0.384536 10.3991 0.588121 10.3991 0.8004C10.3991 1.01268 10.4834 1.21626 10.6336 1.36637L16.4682 7.20004H0.800102C0.58794 7.20004 0.384468 7.28432 0.234447 7.43435C0.0844269 7.58437 0.000146866 7.78784 0.000146866 8Z"
                            fill="#605B52"
                          />
                        </svg>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlacePopup;
