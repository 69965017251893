import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import React from "react";
import { useState, useEffect } from "react";
import {
  colorScale,
  countries,
  missingCountries,
  countryName,
} from "./Countries";
import html2canvas from "html2canvas";
import MyModal from "./Modal";
import axios from "axios";
import "./Worldmap.css";
import { BASEURL } from "../../api";

import { auth } from "../../firebase/firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/tripticklogowhite.png";
import { signOut, onAuthStateChanged } from "firebase/auth";
import * as htmlToImage from 'html-to-image';

function WorldMap({isLoggedIn, setIsLoggedIn}) {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate(`/login`);
  };

  const bearerToken = localStorage.getItem("token");


  const signOutApiCall = async () => {
    try {
      const response = await fetch(`${BASEURL}/signoutuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify({ token: localStorage.getItem("token") }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      signOut(auth)
        .then(() => {
          setUserData({});
        })
        .catch((error) => {
          console.log({ error });
        });

      localStorage.clear();
      setIsLoggedIn(false);
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error Signing out user", error);
      // searchNearByPlace(value, isAskedQueryRelatedToItinerary);
    }
  };

  const Logout = () => {
    signOutApiCall();
  };


  const hasToken =
    localStorage.getItem("token") || localStorage.getItem("googletoken");
  // Data via Fetch
  const [visitedCountryCountData, setvisitedCountryCountData] = useState(null);
  const [wishlistCountryCountData, setwishlistCountryCountData] =
    useState(null);
  const [visitedCountryPercenatge, setvisitedCountryPercenatge] = useState(0);

  const [visitedCountryListData, setvisitedCountryListData] = useState([]);
  const [wishlistCountryListData, setwishlistCountryListData] = useState([]);
  const [countryNameState, setCountryNameState] = useState([]);
  const [countryValueState, setCountryValueState] = useState([]);
  const [overlayLoaderStyle, setoverlayLoaderStyleState] = useState(false);
  const [currentCountryCode, setCurrentCountryCode] = useState(null);
  const [isVisitedCountry, setIsVisitedCountry] = useState(false);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const [makeButtonDisable, setMakeButtonDisable] = useState(false);
  const [showMenuContent, setShowMenuContent] = useState(false);

  useEffect(() => {
    // Your Fetch request with the bearer token
    const bearerToken = localStorage.getItem("token");

    fetch(`${BASEURL}/fetch-user-tour-info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data, for example, set it in the state
        setvisitedCountryCountData(data.total_countries_visited);
        setwishlistCountryCountData(data.total_countries_in_wishlist);
        setvisitedCountryListData(data.visited_country_list);
        setwishlistCountryListData(data.wishlist_country_list);
        setvisitedCountryPercenatge(data.visited_country_percenatge);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error fetching data:", error);
      });

    // setting state for countries
    setCountryNameState(countryName);
    setCountryValueState(countries);
  }, []); // Empty dependency array for running the effect only once

  const [isModalActive, setIsModalActive] = useState(false);
  const [countryNameChoose, setCountryNameChoose] = useState("");
  const [makeplusMinusIconDisable, setMakeplusMinusIconDisable] = useState(false);

  const handleRegionClick = (event, code) => {

    // code parameter contains the ISO country code of the clicked country
    const countryCode = code || "Unknown Country";
    setIsModalActive(true);
    setCountryNameChoose(countryName[countryCode.toString()]);
    setCurrentCountryCode(countryCode);
  
  };

  function handleZoomOutCompletely() {
    const zoomOutButton = document.querySelector(".jvectormap-zoomout");
  
    function clickZoomOut() {
      zoomOutButton.click();
    }
  
    // Repeat clicking the zoomOut button until the map is zoomed out completely
    const maxZoomOutClicks = 7; // Adjust the number of clicks as needed
    let clickCount = 0;
  
    function tryZoomOut() {
      if (clickCount < maxZoomOutClicks) {
        clickZoomOut();
        clickCount++;
        setTimeout(tryZoomOut, 200); // Wait for a short delay before the next click
      } else {
        // Map is zoomed out completely, now save the image
        saveImageAfterZoomedOut();
      }
    }
  
    // Start the zoom out process
    tryZoomOut();
  }
  
  function saveImageAfterZoomedOut() {
    // Hide zoom buttons
    const zoomInElements = document.querySelectorAll('.jvectormap-zoomin');
    const zoomOutElements = document.querySelectorAll('.jvectormap-zoomout');
    zoomInElements.forEach(element => (element.style.display = 'none'));
    zoomOutElements.forEach(element => (element.style.display = 'none'));
  
    // Reference to the VectorMap container
    const mapContainer = document.getElementById("vector-map-main");
  
    // Use html-to-image to capture the screenshot
    htmlToImage.toPng(mapContainer)
      .then(function (dataUrl) {
        // Create a link element to download the image
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "vector-map-screenshot.png";
  
        // Trigger a click on the link to start the download
        a.click();
  
        // Show zoom buttons again after a delay
        setTimeout(() => {
          zoomInElements.forEach(element => (element.style.display = 'block'));
          zoomOutElements.forEach(element => (element.style.display = 'block'));
        }, 500);
      })
      .catch(function (error) {
        console.error('Error capturing screenshot:', error);
      });
  }
  
  function handleSaveImage() {
    handleZoomOutCompletely();
  }
  

  // After API Call map color changes
  useEffect(() => {
    // for visited countries
    // console.log("After API Call map color changes")
    const matchingCountryKeys = Object.keys(countryNameState).filter((key) =>
      visitedCountryListData.includes(countryNameState[key])
    );

    // Create a copy of the original object
    let updatedCountryValueState = { ...countryValueState };

    // Update the values to 0 for matching keys
    matchingCountryKeys.forEach((matchingKey) => {
      if (updatedCountryValueState[matchingKey] !== undefined) {
        updatedCountryValueState[matchingKey] = 100;
      }
    });

    // for wishlist countries
    const wishlist_matchingCountryKeys = Object.keys(countryNameState).filter(
      (key) => wishlistCountryListData.includes(countryNameState[key])
    );

    let updatedCountryValueState2 = { ...updatedCountryValueState };

    wishlist_matchingCountryKeys.forEach((matchingKey) => {
      if (updatedCountryValueState2[matchingKey] !== undefined) {
        updatedCountryValueState2[matchingKey] = 50;
      }
    });

    setCountryValueState((prevState) => {
      return { ...prevState, ...updatedCountryValueState2 };
    });
  }, [
    visitedCountryListData,
    wishlistCountryListData,
    
  ]);

  useEffect(() => {
    // console.log("currentCountryCode",currentCountryCode)
    // console.log("countryValueState", countryValueState[currentCountryCode]);
    if (
      currentCountryCode != null &&
      countryValueState[currentCountryCode] == 100
    ) {
      setIsVisitedCountry(true);
      setMakeButtonDisable(true);
      setIsAddedToWishlist(true);
      
      // setAddOrRemoveToWishlistButton("Remove from");
    } else if (
      currentCountryCode != null &&
      countryValueState[currentCountryCode] == 50
    ) {
      setIsAddedToWishlist(true);
      setMakeButtonDisable(false);
      // setAddOrRemoveToWishlistButton("Remove from");
    }

    else{

      setIsAddedToWishlist(false);
      setIsVisitedCountry(false);
      setMakeButtonDisable(false);
        // setAddOrRemoveToWishlistButton("Add to");
      }
  }, [countryValueState, currentCountryCode]);

  // useEffect(() => {
  //   console.log("currentCountryCode", currentCountryCode);
  // }, [currentCountryCode]);

  // useEffect(() => {
  //   console.log("countryNameChoose", countryNameChoose);
  // }, [countryNameChoose]);
  const handleButtonClick = () =>{
    setShowMenuContent(!showMenuContent);

  };

  return (
    <>
      <div className="navbar-container header-bg-dark desk-head">
        <div className="navbar">
          <div className="navbar-content1">
            <Link to="/">
              <img src={logo} alt="openAI" className="logo-image" />
            </Link>
          </div>
          <div className="navbar-content2">
            <div>
              {!hasToken && (
                <button onClick={goToLogin} type="button" className="login-btn">
                  Iniciar sesión
                </button>
              )}
              {hasToken && (
                <div className="wrapper">
                  <div className="profile-card__name" onClick={Logout}>
                  Cerrar sesión
                  </div>
                </div>
              )}
            </div>
            {hasToken && (
              <div>
                <Link className="custom-my-trips-btn" to="/my-trips">Mis viajes</Link>
                <Link className="custom-world-map-btn" to="/world-map">Mapa</Link>
                <Link className="custom-property-listing-btn" to="/property-listing">listado de propiedades</Link>
              </div>
            
            )}
          </div>
        </div>
      </div>

      <div className="navbar-container header-bg-dark mob-head">
      
      <div className="navbar">
        <div className="navbar-content1" >
        <Link to="/">
              <img src={logo} alt="openAI" className="logo-image" />
            </Link>
          <div className="hambuger-menu">
            <button type="button" className="menu-icon" onClick={handleButtonClick}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="32px" id="Layer_1" style={{"enable-background":"new 0 0 32 32"}} version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg></button>
              <div className="menu-content" style={{height: showMenuContent==true ? `auto` : `0%`}}>
                <div className="inner-menu">
          <div>
            {!hasToken && (
              <button onClick={goToLogin} type="button" className="login-btn">
                Iniciar sesión
              </button>
            )}
            {hasToken && (
              <div className="wrapper">
                <div className="profile-card__name" onClick={Logout}>
                Cerrar sesión
                </div>
              </div>
            )}
          </div>
          {hasToken && (
            <div className="page-links">
              <Link className="custom-my-trips-btn" to="/my-trips">Mis viajes</Link>
                <Link className="custom-world-map-btn" to="/world-map">Mapa</Link>
                <Link className="custom-property-listing-btn" to="/property-listing">listado de propiedades</Link>
            </div>
          
          )}
          </div></div>

        </div>
        </div>
      </div>
    </div>
      <div
        id="overlay"
        style={{ display: overlayLoaderStyle ? "block" : "none" }}
      >
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>
      {isModalActive && (
        <>
          <MyModal
            countryNameChoose={countryNameChoose}
            setIsModalActive={setIsModalActive}
            isModalActive={isModalActive}
            BASEURL={BASEURL}
            setoverlayLoaderStyleState={setoverlayLoaderStyleState}
            setCountryValueState={setCountryValueState}
            countryValueState={countryValueState}
            countryNameState={countryNameState}
            setvisitedCountryCountData={setvisitedCountryCountData}
            setwishlistCountryCountData={setwishlistCountryCountData}
            setvisitedCountryPercenatge={setvisitedCountryPercenatge}
            isVisitedCountry={isVisitedCountry}
            setIsVisitedCountry={setIsVisitedCountry}
            isAddedToWishlist={isAddedToWishlist}
            setIsAddedToWishlist={setIsAddedToWishlist}
            makeButtonDisable={makeButtonDisable}
            setMakeButtonDisable={setMakeButtonDisable}
          />
        </>
      )}

      <div className="map-parent">
        <div id="vector-map-container">
          <div id="vector-map-main">
          
          <VectorMap
            map={worldMill}
            containerStyle={{
              width: "1280px",
              height: "850px",
            }}
            className="vector-gradient-background"
            series={{
              regions: [
                {
                  scale: colorScale,
                  values: countryValueState,
                  min: 0,
                  max: 100,
                },
              ],
            }}
            onRegionClick={handleRegionClick}
            regionLabelStyle={{
              initial: {
                'font-family': 'Arial',
                'font-size': '10',
                'font-weight': 'bold',
                cursor: 'default',
              },
              hover: {
                cursor: 'pointer',
              },
            }}

          />
<div className="map-logo">
            <img src={logo} alt="openAI" className="logo-image" />
          </div>
          {visitedCountryPercenatge > -1 ? (
            <div className="my-visit-info">
              <h4>He visitado el {visitedCountryPercenatge}% del mundo</h4>
              <p className="visited-para">
                {visitedCountryCountData}/204 países visitados
              </p>
              <p className="wishlist-para">
                {wishlistCountryCountData}/204 países deseados
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        </div>
        <div className="row download-here">
          <div className="col-md-6">
            <button style={{ cursor: "pointer" }} onClick={handleSaveImage}>
            Descargar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorldMap;
