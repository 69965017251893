import React, { useState, useEffect } from "react";
import SplitPane, { Pane } from "split-pane-react";
import { FaEye, FaMapMarkerAlt } from "react-icons/fa";
import { shortenString } from "../../helperFunctions/trimFunction";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import MapboxMarkerPopup from "../mapboxMarkerPopup/MapboxMarkerPopup";
import PlacePopup from "../placePopup/PlacePopup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "split-pane-react/esm/themes/default.css";
import clipboard from "clipboard-copy";
import { FaStar } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../../assets/images/tripticklogowhite.png";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "./nearByPlaces.css";
import {
  doc,
  collection,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { BASEURL } from "../../api";
import MapboxMap from "../mapboxMap/MapboxMap";
import MapboxMap2 from "../mapboxMap2/MapboxMap2";
import PlacePopup2 from "../placePopup2/PlacePopup2";
const layoutCSS = {
  height: "100vh",
};

export default function NearByPlaces({
  dataToSendToMapboxForMarkers,
  setDataToSendToMapboxForMarkers,
  uniqueIdOfPlaces,
  triggerAnimation,
  handleSearchedQuery,
  isAskedQueryRelatedToItinerary,
  setPlaceValue,
  setAskedCityNameFromUser,
  setValue,
  setSelected,
  setIsAskedQueryRelatedToItinerary,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyARr66_g-z9Rv7QQcSDmhLuh4KIDxn67h8",
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [splitDirection, setSplitDirection] = useState("vertical");
  const [isHovered, setIsHovered] = useState(null);
  const [hoveredPlaceIndex, setHoveredPlaceIndex] = useState(null);
  const [sizes, setSizes] = useState([1000, "5%", "auto"]);
  const [placesDetails, setPlacesDetails] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  // State to control the popup visibility
  const [showHotelInfo, setShowHotelInfo] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [staysPart, setstaysPart] = useState(true);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [contentDescriptions, setContentDescriptions] = useState([]);
  const [contentname, setContentname] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [loading, setloading] = useState(true);
  const [mapCenterLocation, setMapCenterLocation] = useState({
    lat: 38.923937,
    lng: -101.681874,
  });
  const [searchedData, setSearchedData] = useState([]);
  const [coordinatesArray, setCoordinatesArray] = useState([]);
  const [promptResult, setPromptResult] = useState("");
  const [isLoadedThePromptResult, setIsLoadedThePromptResult] = useState(false);
  const [stateToRenderMapbox, setStateToRenderMapbox] = useState(1);
  const [placesName, setplacesName] = useState([]);
  const [activePlaceOnMap, setActivePlaceOnMap] = useState("");
  const [searchedPlaceName, setSearchedPlaceName] = useState("");
  const [placeNameZoomedInOnLoad, setPlaceNameZoomedInOnLoad] = useState([]);
  const [atFirst, setAtFirst] = useState(1);
  const [sucessfullyLoadedthePlaceName, setsucessfullyLoadedthePlaceName] =
    useState(false);
  const [nameToSendForMarkerType, setNameToSendForMarkerType] = useState("");
  const [searchedPlaceDetailObject, setSearchedPlaceDetailObject] = useState(
    []
  );
  const [searchedPlaceDetailObject2, setSearchedPlaceDetailObject2] = useState(
    []
  );
  const [currentClickedPlaceDetailObject, setCurrentClickedPlaceDetailObject] =
    useState([]);
  const [currentlyClickedPlaceImg, setCurrentlyClickedPlaceImg] = useState("");
  const [currentOptionChoose, setcurrentOptionChoose] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [lengthOfObject, setLengthOfObject] = useState(null);
  const [lengthOfObject1, setLengthOfObject1] = useState(null);
  const [entriesArray, setEntriesArray] = useState([]);
  const [entriesArray1, setEntriesArray1] = useState([]);
  const [entriesArray3, setEntriesArray3] = useState([]);
  const [entriesArray4, setEntriesArray4] = useState([]);
  const [toggleForMobile, setToggleForMobile] = useState(false);

  const bearerToken = localStorage.getItem("token");

  const carouselSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isEverythingReady, setisEverythingReady] = useState(0);
  const [isCoordinatesPresentOfPlaces, setIsCoordinatesPresentOfPlaces] =
    useState(null);
  const [
    isCoordinatesOfSearchedPlacePresent,
    setIsCoordinatesOfSearchedPlacePresent,
  ] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [mainContentToShow, setMainContentToShow] = useState("");

  // Function to open the popup
  const openHotelInfoPopup = (hotel) => {
    setSelectedHotel(hotel);
    setShowHotelInfo(true);
  };

  // Function to close the popup
  const closeHotelInfoPopup = () => {
    setSelectedHotel(null);
    setShowHotelInfo(false);
  };

  function sortByFirstIndexValue(arr) {
    // Sort the array based on the value at index [0]
    arr.sort((a, b) => {
      const valueA = a[0].toLowerCase(); // Assuming you want case-insensitive sorting
      const valueB = b[0].toLowerCase();

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });

    return arr; // Return the sorted array
  }

  useEffect(() => {
    // console.log(entriesArray[0]);
    if (entriesArray.length > 0) {
      setEntriesArray1(sortByFirstIndexValue(entriesArray));
    }
  }, [entriesArray]);

  // useEffect(() => {
  //   console.log("entriesArray1",entriesArray1)
  // }, [entriesArray1])

  useEffect(() => {}, []);
  const { vertical, horizontal, open } = state;
  // const id = window.location.href.split("/").filter(Boolean).pop();
  const id = uniqueIdOfPlaces;

  const sendDetailsToMongoDB = async () => {
    await fetch(`${BASEURL}/fetch-search-data-from-id`, {
      method: "POST", // or "GET" or any other HTTP method you need
      headers: {
        "Content-Type": "application/json", // Set the appropriate content type
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("data.result", data.result);
        setNameToSendForMarkerType(data.result.extractedKeywordFromInput);
        setDataToSendToMapboxForMarkers(data.result.coordinatesOfPlaces);
        setPlaceNameZoomedInOnLoad([data.result.coordinatesOfSearchedPlace]);
        setSearchedPlaceName(data.result.placeValue);
        setSearchedData(data.result);
        setSearchedPlaceDetailObject(data.result.placesPhotoAndDetailsObject);

        setPromptResult(data.result.places.choices[0].message.content);
        setIsLoadedThePromptResult(true);
        setAtFirst(1);
        setLengthOfObject(
          Object.keys(data.result.placesPhotoAndDetailsObject).length
        );
        setEntriesArray(
          Object.entries(data.result.placesPhotoAndDetailsObject)
        );
        setPlaceValue(data.result.placeValue);
        setValue(data.result.askedQuery);
        setAskedCityNameFromUser(true);
        setSelected(data.result.coordinatesOfSearchedPlace.coordinates);
        setIsAskedQueryRelatedToItinerary(
          data.result.isAskedQueryRelatedToItinerary
        );
      });
  };

  useEffect(() => {
    sendDetailsToMongoDB();
  }, []);

  function sortArrayByName(array) {
    // Use the sort method with a comparison function
    array.sort((a, b) => {
      // Extract the names from the object keys
      const nameA = Object.keys(a)[0].split(",")[0]; // "Toronto"
      const nameB = Object.keys(b)[0].split(",")[0]; // "NiagaraFalls"

      // Compare the names and return the comparison result
      return nameA.localeCompare(nameB); // Using localeCompare for string comparison
    });

    return array;
  }

  useEffect(() => {
    if (searchedPlaceDetailObject2) {
      setEntriesArray3(Object.entries(searchedPlaceDetailObject2));
    }
  }, [searchedPlaceDetailObject2]);

  useEffect(() => {
    // console.log(entriesArray[0]);
    if (entriesArray3.length > 0) {
      setEntriesArray4(sortByFirstIndexValue(entriesArray3));
    }
  }, [entriesArray3]);

  useEffect(() => {
    if (searchedPlaceDetailObject2) {
      setLengthOfObject1(Object.keys(searchedPlaceDetailObject2).length);
    }
  }, [searchedPlaceDetailObject2]);

  // useEffect(() => {
  //   console.log("searchedPlaceDetailObjectcscscscs",searchedPlaceDetailObject);
  //   console.log("dataToSendToMapboxForMarkers",dataToSendToMapboxForMarkers)
  // }, [searchedPlaceDetailObject,dataToSendToMapboxForMarkers])

  useEffect(() => {
    setTimeout(() => {
      setStateToRenderMapbox(2);
    }, 10);
  }, []);

  // useEffect(() => {
  //   console.log("entriesArray",entriesArray);
  //   console.log("entriesArray1",entriesArray1)
  //   console.log("entriesArray3",entriesArray3);
  //   console.log("entriesArray4",entriesArray4)
  // }, [entriesArray3,entriesArray4,entriesArray,entriesArray1])

  const fetchDocument = async () => {
    const resultArray = [];
    const resultArrayTwo = [];
    let mydata = [];

    if (searchedData) {
      // console.log("000000000000000000000000000000", searchedData);
      mydata = searchedData;
    }

    if (mydata) {
      // console.log("Document data:", mydata);

      if (
        mydata &&
        mydata.places &&
        mydata.places.choices &&
        mydata.places.choices.length > 0
      ) {
        // console.log("787hghghghjghjghjgh")
        let content = "";
        // console.log("docSnap.data()",docSnap.data());
        mydata.places.choices.forEach((choice) => {
          content += choice.message.content;
        });

        // console.log("content", content);
        setContentData(content);
        // console.log(docSnap.data().location);
        setMapCenterLocation(mydata.location);
        const paragraphs = content.split("\n\n");

        const modifiedString = paragraphs.map((paragraph, paragraphIndex) => (
          <p key={paragraphIndex}>
            {paragraph.split(/(🏨.*?🏨)/g).map((segment, index) => {
              if (index % 2 === 1) {
                const placeObject = dataToSendToMapboxForMarkers.find((place) =>
                  place.placename.includes(segment.slice(2, -2))
                );
                if (placeObject) {
                  return (
                    <span
                      key={index}
                      onMouseOver={() =>
                        handleOnMouseOver(placeObject.placename)
                      }
                    >
                      <b>{placeObject.placename}</b>
                    </span>
                  );
                }
              }
              return <React.Fragment key={index}>{segment}</React.Fragment>;
            })}
          </p>
        ));

        // console.log("modifiedString7878978979878978789789", modifiedString);

        // console.log("121212212121212112121",contentArray);
        setMainContentToShow(modifiedString);
      } else {
        setContentData("No Result Found!");
      }
    } else {
      setContentData("No Result Found!");
    }
  };

  useEffect(() => {
    // fetchAirbnbData();
    fetchDocument();
    setloading(false);
  }, [searchedData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSplitDirection(windowWidth <= 768 ? "horizontal" : "vertical");
    const newSizes = [...sizes];
    newSizes[1] =
      windowWidth <= 768 ? "20%" : windowWidth <= 1200 ? "8%" : "5%";
    setSizes(newSizes);
  }, [windowWidth]);

  const handleOnMouseOver = (place) => {
    // console.log(place)
    if (place !== null) {
      // console.log(place)
      setActivePlaceOnMap(place.replace(/\s/g, ""));
      setToggleForMobile(!toggleForMobile);
    }
  };

  // Update screen width on resize
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Attach and remove event listener on component mount/unmount
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function findData(placeName, data) {
    for (let i = 0; i < data.length; i++) {
      const placeDetail = data[i];
      if (placeName in placeDetail) {
        return placeDetail[placeName];
      }
    }
    return null; // Return null if the placeName is not found in the data
  }

  useEffect(() => {
    if (currentOptionChoose == "openPopup") {
      
        setCurrentClickedPlaceDetailObject(
          searchedPlaceDetailObject[currentlyClickedPlaceImg]
        );

    } else if (currentOptionChoose == "closePopup") {
      // setCurrentClickedPlaceDetailObject({});
    }
  }, [currentlyClickedPlaceImg]);

  const handlefunction = (value, current_idx) => {
    // console.log("Value-",value)
    setCurrentlyClickedPlaceImg(value);
    setcurrentOptionChoose("openPopup");
    setCurrentIndex(current_idx);
  };

  // useEffect(() => {
  //   console.log(isAskedQueryRelatedToItinerary);
  // }, [isAskedQueryRelatedToItinerary]);

  // useEffect(() => {

  // }, [third])

  // useEffect(() => {
  //   if (
  //     isAskedQueryRelatedToItinerary == true &&
  //     searchedPlaceDetailObject.length > 0
  //   ) {
  //     const mergedObject = searchedPlaceDetailObject.reduce((acc, obj) => {
  //       return { ...acc, ...obj };
  //     }, {});

  //     setSearchedPlaceDetailObject2(mergedObject);
  //   }
  // }, [searchedPlaceDetailObject]);

  // useEffect(() => {
  //   console.log("searchedPlaceDetailObject2",searchedPlaceDetailObject2)
  // }, [searchedPlaceDetailObject2])

  function findIndexForKey(obj, keyToFind) {
    // Convert the keys of the object into an array
    const keys = Object.keys(obj);

    // Find the index of the key in the array
    const index = keys.indexOf(keyToFind);

    // Return the index
    return index !== -1 ? index : null; // Return null if key is not found
  }

  const handleClick = () => {
    setToggleForMobile(!toggleForMobile);
  };

  const findNumberOfLocationForItineraryData = (myarray) => {
    let count = 0;
    myarray.map((item) => {
      count += Object.keys(item).length;
    });
    return count;
  };

  function removeSpecialSymbols(inputString) {
    // Use a regular expression to remove special symbols, including emojis and specific box-like characters
    const cleanedString = inputString.replace(
      /[^\w\s🏨\u20E3^$%&*+?.()|\[\]{}<>'"`-]/gi,
      ""
    );

    return cleanedString;
  }

  function removeSpecialCharacter(inputString) {
    // Replace the ⃣ character with an empty string
    return inputString.replace(/⃣/g, "");
  }

  return (
    <>
      {screenWidth < 992 ? (
        <>
        {entriesArray1.length > 0 ? (
          <section className="result-section">
            <div className="container-fluid">
              <motion.div
                initial={{ x: 0, y: "100%", opacity: 1 }}
                animate={{
                  x: triggerAnimation ? 0 : 0,
                  y: triggerAnimation ? "0%" : "100%",
                  opacity: triggerAnimation ? 1 : 1,
                }}
                transition={{ duration: 2 }}
              >
                <div
                  className={`result-wrapper ${
                    toggleForMobile ? "active-class-for-mobile" : ""
                  }`}
                >
                  <div className="left-result">
                    <div
                      className="result-outer-wrapper"
                      onClick={handleClick}
                    >
                      <div className="result-line-top">
                        {toggleForMobile ? (
                          <div className="down-arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                            </svg>
                          </div>
                        ) : (
                          <div className="up-arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="result-heading-wrapper">
                        <div className="heading-child">
                          <h2 className="result-heading">Your trip</h2>
                          <span className="result-tag">
                            {entriesArray1.length > 0
                              ? `${entriesArray1.length} locations`
                              : ""}
                          </span>
                        </div>
                        <button
                          className="find-btn"
                          onClick={handleSearchedQuery}
                        >
                          Find more results
                        </button>
                      </div>
                    </div>
                    <div className="result-card-wrapper">
                      {entriesArray1.length > 0 ? (
                        entriesArray1.map((place, idx) => {
                          return (
                            <>
                              <div className="result-card" id={idx}>
                                <div className="result-count">
                                  <span className="circle-count">
                                    {idx + 1}
                                  </span>
                                  <div className="vertical-line"></div>
                                </div>
                                <div className="card-full-wrapper">
                                  <div className="card-img-text-wrapper">
                                    <div className="card-img-wrapper">
                                      <img
                                        src={place[1].place_photo_url}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="result-card-txt">
                                      <h4
                                        className="card-heading"
                                        onMouseOver={() =>
                                          handleOnMouseOver(place[0])
                                        }
                                      >
                                        {
                                          place[1].place_detail
                                            .displayName.text
                                        }
                                      </h4>
                                      <p className="card-para">
                                        {shortenString(
                                          removeSpecialCharacter(
                                            place[1].place_description
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="result-card-button">
                                    <button
                                      className="open-btn view"
                                      onClick={() => {
                                        handlefunction(place[0], idx + 1);
                                      }}
                                    >
                                      View details
                                    </button>
                                    <button
                                      className="open-btn map"
                                      onClick={() =>
                                        handleOnMouseOver(place[0])
                                      }
                                    >
                                      Go to maps
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                          // Do something with key and value
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="right-map-wrapper">
                    {entriesArray1.length > 0 ? (
                      <MapboxMap
                        key={stateToRenderMapbox}
                        dataToSendToMapboxForMarkers={
                          dataToSendToMapboxForMarkers
                        }
                        setDataToSendToMapboxForMarkers={
                          setDataToSendToMapboxForMarkers
                        }
                        activePlaceOnMap={activePlaceOnMap}
                        setAtFirst={setAtFirst}
                        atFirst={atFirst}
                        placeNameZoomedInOnLoad={placeNameZoomedInOnLoad}
                        nameToSendForMarkerType={nameToSendForMarkerType}
                        searchedPlaceDetailObject={
                          searchedPlaceDetailObject
                        }
                        currentlyClickedPlaceImg={
                          currentlyClickedPlaceImg
                        }
                        setCurrentlyClickedPlaceImg={
                          setCurrentlyClickedPlaceImg
                        }
                        currentOptionChoose={currentOptionChoose}
                        setcurrentOptionChoose={setcurrentOptionChoose}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        entriesArray={entriesArray}
                        entriesArray1={entriesArray1}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </motion.div>
            </div>

            {currentlyClickedPlaceImg !== "" ? (
              <PlacePopup
                currentlyClickedPlaceImg={currentlyClickedPlaceImg}
                setCurrentlyClickedPlaceImg={setCurrentlyClickedPlaceImg}
                currentClickedPlaceDetailObject={
                  currentClickedPlaceDetailObject
                }
                setCurrentClickedPlaceDetailObject={
                  setCurrentClickedPlaceDetailObject
                }
                currentOptionChoose={currentOptionChoose}
                setcurrentOptionChoose={setcurrentOptionChoose}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                lengthOfObject={lengthOfObject}
                searchedPlaceDetailObject={searchedPlaceDetailObject}
                entriesArray={entriesArray}
                entriesArray1={entriesArray1}
                screenWidth={screenWidth}
              />
            ) : (
              <PlacePopup
                currentlyClickedPlaceImg={currentlyClickedPlaceImg}
                setCurrentlyClickedPlaceImg={setCurrentlyClickedPlaceImg}
                currentClickedPlaceDetailObject={
                  currentClickedPlaceDetailObject
                }
                setCurrentClickedPlaceDetailObject={
                  setCurrentClickedPlaceDetailObject
                }
                currentOptionChoose={currentOptionChoose}
                setcurrentOptionChoose={setcurrentOptionChoose}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                lengthOfObject={lengthOfObject}
                searchedPlaceDetailObject={searchedPlaceDetailObject}
                entriesArray={entriesArray}
                entriesArray1={entriesArray1}
                screenWidth={screenWidth}
              />
            )}
          </section>
        ) : (
          <></>
        )}
      </>
      ) : (
        <>
        {entriesArray1.length > 0 ? (
          <section className="result-section">
            <div className="container-fluid">
              <motion.div
                initial={{ x: 0, y: "100%", opacity: 1 }}
                animate={{
                  x: triggerAnimation ? 0 : 0,
                  y: triggerAnimation ? "0%" : "100%",
                  opacity: triggerAnimation ? 1 : 1,
                }}
                transition={{ duration: 2 }}
              >
                <div className="result-wrapper">
                  <div className="left-result">
                    <div className="result-heading-wrapper">
                      <div className="heading-child">
                        <h2 className="result-heading">Your trip</h2>
                        <span className="result-tag">
                          {entriesArray1.length > 0
                            ? `${entriesArray1.length} locations`
                            : ""}
                        </span>
                      </div>
                      <button
                        className="find-btn"
                        onClick={handleSearchedQuery}
                      >
                        Find more results
                      </button>
                    </div>
                    {entriesArray1.length > 0 ? (
                      entriesArray1.map((place, idx) => {
                        return (
                          <>
                            <div className="result-card" id={idx}>
                              <div className="result-count">
                                <span className="circle-count">
                                  {idx + 1}
                                </span>
                                <div className="vertical-line"></div>
                              </div>
                              <div className="card-img-wrapper">
                                <img
                                  src={place[1].place_photo_url}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="result-card-txt">
                                <h4
                                  className="card-heading"
                                  onMouseOver={() =>
                                    handleOnMouseOver(place[0])
                                  }
                                >
                                  {place[1].place_detail.displayName.text}
                                </h4>
                                <p className="card-para">
                                  {shortenString(
                                    removeSpecialCharacter(
                                      place[1].place_description
                                    )
                                  )}
                                </p>
                                <div className="result-card-button">
                                  <button
                                    className="open-btn view"
                                    onClick={() => {
                                      handlefunction(place[0], idx + 1);
                                    }}
                                  >
                                    View details
                                  </button>
                                  <button
                                    className="open-btn map"
                                    onClick={() =>
                                      handleOnMouseOver(place[0])
                                    }
                                  >
                                    Go to maps
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                        // Do something with key and value
                      })
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="right-map-wrapper">
                    {entriesArray1.length > 0 ? (
                      <MapboxMap
                        key={stateToRenderMapbox}
                        dataToSendToMapboxForMarkers={
                          dataToSendToMapboxForMarkers
                        }
                        setDataToSendToMapboxForMarkers={
                          setDataToSendToMapboxForMarkers
                        }
                        activePlaceOnMap={activePlaceOnMap}
                        setAtFirst={setAtFirst}
                        atFirst={atFirst}
                        placeNameZoomedInOnLoad={placeNameZoomedInOnLoad}
                        nameToSendForMarkerType={nameToSendForMarkerType}
                        searchedPlaceDetailObject={
                          searchedPlaceDetailObject
                        }
                        currentlyClickedPlaceImg={
                          currentlyClickedPlaceImg
                        }
                        setCurrentlyClickedPlaceImg={
                          setCurrentlyClickedPlaceImg
                        }
                        currentOptionChoose={currentOptionChoose}
                        setcurrentOptionChoose={setcurrentOptionChoose}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        entriesArray={entriesArray}
                        entriesArray1={entriesArray1}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </motion.div>
            </div>

            {currentlyClickedPlaceImg !== "" ? (
              <PlacePopup
                currentlyClickedPlaceImg={currentlyClickedPlaceImg}
                setCurrentlyClickedPlaceImg={setCurrentlyClickedPlaceImg}
                currentClickedPlaceDetailObject={
                  currentClickedPlaceDetailObject
                }
                setCurrentClickedPlaceDetailObject={
                  setCurrentClickedPlaceDetailObject
                }
                currentOptionChoose={currentOptionChoose}
                setcurrentOptionChoose={setcurrentOptionChoose}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                lengthOfObject={lengthOfObject}
                searchedPlaceDetailObject={searchedPlaceDetailObject}
                entriesArray={entriesArray}
                entriesArray1={entriesArray1}
              />
            ) : (
              <PlacePopup
                currentlyClickedPlaceImg={currentlyClickedPlaceImg}
                setCurrentlyClickedPlaceImg={setCurrentlyClickedPlaceImg}
                currentClickedPlaceDetailObject={
                  currentClickedPlaceDetailObject
                }
                setCurrentClickedPlaceDetailObject={
                  setCurrentClickedPlaceDetailObject
                }
                currentOptionChoose={currentOptionChoose}
                setcurrentOptionChoose={setcurrentOptionChoose}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                lengthOfObject={lengthOfObject}
                searchedPlaceDetailObject={searchedPlaceDetailObject}
                entriesArray={entriesArray}
                entriesArray1={entriesArray1}
              />
            )}
          </section>
        ) : (
          <></>
        )}
      </>
      )}
    </>
  );
}
